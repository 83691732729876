import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import useAccount from 'hooks/useAccount';
import { setPostsAsRead } from 'services/user.service';
import Grid from '@material-ui/core/Grid';
import * as _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { SearchAndFilterSection } from 'pages/Conversations';
import * as R from 'ramda';
import Modal from 'components/UI/Modal';
import { setCohealerInfoForClient } from 'actions/cohealerInfo';
import CreatePost from './components/CreatePost/CreatePost';
import PostList from './components/PostList';
import Post from './components/Post/Post';
import * as actions from '../../../actions/community';
import useContribution from '../hooks/useContribution';
import { COUNT_POSTS_BY_PAGE } from '../../../constants';
import { useHttp, useRouter, useUnifiedCommunity } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { memberPodsService } from '../../../services/memberPods/memberPods.service';
import { getPost, searchByKeyword, getHashTagByContributionId } from 'services/community.service';
import { determineColorToUse } from 'services/contributions.service';
import { getThemedColors } from 'services/contributions.service';
import { pluckKeys } from 'utils/utils';
import { updateCurrentGroup, updateGroupUnreadCount } from 'actions/communitySections';
import { UserRoles } from 'helpers/constants';
import { redirectTo } from 'services/links';
import NewPosts from 'pages/Conversations/Components/NewPosts';

const StyledWrap = styled(Grid)`
  justify-content: center;
  @media screen and (max-width: 767px) {
    width: 95%;
    margin: auto;
  }
  ${({ mobileView }) =>
    mobileView &&
    `
  padding-left: 0;
  `}
`;

const StyledSocialContainer = styled(Grid)`
  ${({ mobileView }) => {
    return (
      !mobileView &&
      `max-height: calc(100vh - 180px);
       min-height: calc(100vh - 180px);
       overflow-y: scroll;
       scrollbar-width: none;
       `
    );
  }};
`;

const ContributionSocialContainer = ({ communityPosts, subSections, archivedGroups, currentGroup, mainGroup }) => {
  const dispatch = useDispatch();
  const { postId, groupId } = useParams();
  const contribution = useContribution();
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const participants = contribution?.participants;
  const [users, setUsers] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const { request } = useHttp();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const colorToUse = determineColorToUse(isUnifiedCommunity ? {} : contribution);
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [pods, setPods] = useState([]);
  const [post, setPost] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetchedPosts, setFetchedPosts] = useState(1);
  const [supportPopup, setSupportPopup] = useState(false);
  const [hidin, sethidin] = useState(false);
  const sectionsRef = useRef(false);
  const {
    match: { url },
    history,
  } = useRouter();
  const [keywordsLoaded, setKeywordsLoaded] = useState(false);
  const [crossClicked, setCrossClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);
  const [showKeywordMenu, setShowKeywordMenu] = useState(true);
  const [searchedWord, setSearchedWord] = useState('');
  const isKeywordMenuOpen = searchedWord?.length > 0 && showKeywordMenu;
  const [keywords, setKeywords] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [selectedKeyWord, setSelectedKeyWord] = useState('');
  const [searchLoader, setSearchLoader] = useState(true);
  const [renderPosts, setRenderPosts] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const selector = state => state?.communities;
  const { totalUnreadPostCountAllCommunities } = useSelector(selector);
  const [showAllPostsTag, setShowAllPostsTag] = useState(totalUnreadPostCountAllCommunities > 0);
  useEffect(() => {
    if (contribution?.isCommunityFeatureHiddden) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, []);

  const togglePopup = () => {
    setShowModal(!showModal);
    if (currentRole === UserRoles.client) {
      redirectTo(`/contribution-view/${contribution.id}/about`);
    } else {
      redirectTo(`/contribution-view/${contribution.id}/community/clients`);
    }
  };
  useEffect(() => {
    getHashTagByContributionId(contribution.id).then(res => {
      setHashtags(res);
    });
  }, [contribution.id]);

  useEffect(() => {
    if (participants.length > 0) {
      const particips = [];
      for (let p of participants) {
        const name = {
          title: `${p.firstName} ${p.lastName}`,
          value: p.id,
        };
        particips.push(name);
      }

      for (let p of contribution?.contributionPartners) {
        const name = {
          title: `${p.firstName} ${p.lastName}`,
          value: p.userId,
        };
        particips.push(name);
      }

      const currentUser = {
        title: `${user?.firstName}${user?.lastName}`,
        value: user?.id,
      };
      const coachUser = {
        title: `${contribution?.serviceProviderName?.trim()}`,
        value: contribution?.userId,
      };
      if (!isCoach || user?.isPartnerCoach) {
        particips.push(coachUser);
      } else {
        particips.push(currentUser);
      }

      setUsers(particips);
    }
  }, [participants, user]);

  const [filterParams, setFilterParams] = useState({
    users: [],
    taggs: [],
    communities: [],
  });
  const noneIsSelectedInUsers = filterParams?.users?.length === 0;
  const noneIsSelectedInTaggs = filterParams?.taggs?.length === 0;
  const noneIsSelectedInCommunities = filterParams?.communities?.length === 0;
  const isFilterApplied = filterParams?.users?.length > 0 || filterParams?.taggs?.length;
  const hasMoreSearchedPosts = communityPosts.posts.length < communityPosts.totalPages;
  const hasMoreCommunityPosts = communityPosts.currentPage < communityPosts.totalPages;
  const hasMorePosts =
    !!searchedWord || !noneIsSelectedInTaggs || !noneIsSelectedInUsers ? hasMoreSearchedPosts : hasMoreCommunityPosts;

  const fetchPosts = () => {
    if (currentGroup?.id) {
      setIsLoad(false);
    }
    dispatch(
      actions.getAllPostsForContribution(
        `${contribution.id}/${1}/${COUNT_POSTS_BY_PAGE}${
          !_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
        }`,
      ),
    )
      .then(() => setPageNumber(communityPosts?.currentPage))
      .then(() => setSearchLoader(false))
      .then(() => setIsLoad(true))
      .then(() =>
        setPostsAsRead({
          contributionId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : contribution.id,
          userId: user.id,
        }),
      );
  };

  const fetchSearchedPosts = data => {
    setRenderPosts(false);
    dispatch(actions.getAllPostsForKeyword(data, groupId))
      .then(() => setIsLoad(true))
      .then(() => setSearchLoader(false))
      .then(() => setRenderPosts(true))
      .then(() => setPostsAsRead({ contributionId: contribution?.id, userId: user.id }));
  };

  const { themedCardBackgroundColor } = getThemedColors(contribution);

  const getPostApi = async id => {
    try {
      let response = await getPost(id);
      setPost(response);
      setSupportPopup(true);
      setIsLoad(true);
    } catch {
      setSupportPopup(false);
      setIsLoad(false);
    }
  };

  const onSearchChange = useCallback(
    val => {
      setKeywordsLoaded(false);
      setSearchedWord(val);
      setShowKeywordMenu(true);
      if (val?.length > 0) {
        try {
          searchByKeyword(contribution?.id, val.toLowerCase().trim(), groupId).then(res => {
            setKeywords(res);
            setKeywordsLoaded(true);
          });
        } catch {
          setKeywordsLoaded(true);
        }
      } else {
        setKeywordsLoaded(true);
        setKeywords([]);
      }
    },
    [groupId],
  );

  const onCrossClick = useCallback(() => {
    setCrossClicked(true);
    setSearchedWord('');
    setSearchLoader(true);
    setShowKeywordMenu(true);
  }, [groupId]);

  const onClearFilter = useCallback(() => {
    setClearClicked(true);
    setSearchLoader(true);
    setShowKeywordMenu(true);
  }, [groupId]);

  useEffect(() => {
    setShowAllPostsTag(totalUnreadPostCountAllCommunities > 0);
  }, [totalUnreadPostCountAllCommunities]);

  useEffect(() => {
    setPageNumber(1);
  }, []);

  useEffect(() => {
    if (!searchedWord?.length) {
      onCrossClick();
    }
  }, [searchedWord, groupId]);

  useEffect(() => {
    onCrossClick();
  }, [groupId]);
  useEffect(() => {
    onClearFilter();
  }, [groupId]);

  useEffect(() => {
    if (
      filterParams?.users?.length > 0 &&
      !noneIsSelectedInUsers &&
      filterParams?.taggs?.length > 0 &&
      !noneIsSelectedInTaggs
    ) {
      setNotificationsCount(2);
    } else if (
      (filterParams?.users?.length > 0 && !noneIsSelectedInUsers) ||
      (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs)
    ) {
      setNotificationsCount(1);
    } else {
      setNotificationsCount(0);
    }
  }, [filterParams, groupId]);

  useEffect(() => {
    if (postId) {
      getPostApi(postId);
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
    setSearchLoader(true);
    setShowKeywordMenu(true);
  }, []);

  useEffect(() => {
    if ((!searchedWord && crossClicked) || (noneIsSelectedInTaggs && noneIsSelectedInUsers && clearClicked)) {
      setCrossClicked(false);
      setClearClicked(false);
      setPageNumber(1);
      setSearchLoader(true);
      // fetchPosts();
      setShowKeywordMenu(true);
      if (!!searchedWord || !noneIsSelectedInTaggs || !noneIsSelectedInUsers) {
        onKeywordClick({
          keyword: searchedWord,
          skip: 0,
          params: filterParams,
        });
      } else {
        fetchPosts();
      }
    }
  }, [searchedWord, crossClicked, filterParams, clearClicked, groupId]);

  useEffect(() => {
    fetchPosts();
    memberPodsService
      .getContributionPods(contribution.id)
      .then(x => setPods(x))
      .catch(x => setPods([]));
    request(`/Contribution/GetCohealerInfoForClient/${contribution.userId}`, 'GET').then(
      R.compose(dispatch, setCohealerInfoForClient),
    );
  }, [dispatch, contribution.id, currentGroup?.id]);

  const resetCurrentGroup = () => {
    dispatch(
      updateCurrentGroup.update({
        group: mainGroup,
      }),
    );
  };
  // const onKeywordClick = useCallback(
  //   input => {
  //     const { keyword, skip, params } = input;
  //     const filters = params || filterParams;
  //     const noneIsSelectedInUsersFilter = filters?.users?.length == 1 && filters?.users?.includes('NONE');
  //     const noneIsSelectedInTaggsFilter = filters?.taggs?.length == 1 && filters?.taggs?.includes('NONE');
  //     const noneIsSelectedInCommunitiesFilter =
  //       filters?.communities?.length == 1 && filters?.communities?.includes('NONE');

  //     if (!(skip > 0)) {
  //       setSearchLoader(true);
  //     }
  //     setSelectedKeyWord(keyword);
  //     setSearchedWord(keyword);
  //     setShowKeywordMenu(false);
  //     let data;

  //     if (
  //       keyword &&
  //       ((filterParams?.users?.length > 0 && !noneIsSelectedInUsers) ||
  //         (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs))
  //     ) {
  //       data = {
  //         contributionId: contribution?.id,
  //         keywords: keyword,
  //         skip: skip,
  //         take: COUNT_POSTS_BY_PAGE,
  //       };
  //       if (
  //         filterParams?.users?.length > 0 &&
  //         !noneIsSelectedInUsers &&
  //         filterParams?.taggs?.length > 0 &&
  //         !noneIsSelectedInTaggs
  //       ) {
  //         data = {
  //           ...data,
  //           users: filterParams?.users,
  //           taggs: filterParams?.taggs,
  //         };
  //       } else if (filterParams?.users?.length > 0 && !noneIsSelectedInUsers) {
  //         data = {
  //           ...data,
  //           users: filterParams?.users,
  //         };
  //       } else if (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs) {
  //         data = {
  //           ...data,
  //           taggs: filterParams?.taggs,
  //         };
  //       }
  //     } else if (keyword) {
  //       data = {
  //         contributionId: contribution?.id,
  //         keywords: keyword,
  //         skip: skip,
  //         take: COUNT_POSTS_BY_PAGE,
  //       };
  //     } else if (
  //       (filterParams?.users?.length > 0 && !noneIsSelectedInUsers) ||
  //       (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs)
  //     ) {
  //       data = {
  //         contributionId: contribution?.id,
  //         skip: skip,
  //         take: COUNT_POSTS_BY_PAGE,
  //       };
  //       if (
  //         (filterParams?.users?.length > 0 &&
  //           !(filterParams?.users?.length == 1 && filterParams?.users?.includes('NONE'))) ||
  //         (filterParams?.taggs?.length > 0 &&
  //           !(filterParams?.taggs?.length == 1 && filterParams?.taggs?.includes('NONE')))
  //       ) {
  //         data = {
  //           ...data,
  //           users: filterParams?.users,
  //           taggs: filterParams?.taggs,
  //         };
  //       } else if (
  //         filterParams?.users?.length > 0 &&
  //         !(filterParams?.users?.length == 1 && filterParams?.users?.includes('NONE'))
  //       ) {
  //         data = {
  //           ...data,
  //           users: filterParams?.users,
  //         };
  //       } else if (
  //         filterParams?.taggs?.length > 0 &&
  //         !(filterParams?.taggs?.length == 1 && filterParams?.taggs?.includes('NONE'))
  //       ) {
  //         data = {
  //           ...data,
  //           taggs: filterParams?.taggs,
  //         };
  //       }
  //     }
  //     fetchSearchedPosts(data);
  //   },
  //   [filterParams],
  // );

  const onKeywordClick = useCallback(
    input => {
      const { keyword, skip, params } = input;
      const filters = params || filterParams;
      const noneIsSelectedInUsersFilter = filters?.users?.length == 1 && filters?.users[0].value === 'NONE';
      const noneIsSelectedInTaggsFilter = filters?.taggs?.length == 1 && filters?.taggs?.includes('NONE');
      const noneIsSelectedInCommunitiesFilter =
        filters?.communities?.length == 1 && filters?.communities[0].id === 'NONE';

      if (!(skip > 0)) {
        setSearchLoader(true);
      }
      setSelectedKeyWord(keyword);
      setSearchedWord(keyword);
      setShowKeywordMenu(false);
      let data;

      if (
        keyword &&
        ((filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) ||
          (filters?.users?.length > 0 && !noneIsSelectedInUsersFilter))
      ) {
        data = {
          contributionId: contribution?.id,
          keywords: keyword,
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
          groupId: groupId,
        };
        if (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) {
          data = {
            ...data,
            taggs: filters?.taggs,
            groupId: groupId,
          };
        }
        if (filters?.users?.length > 0 && !noneIsSelectedInUsersFilter) {
          const userIds = filters?.users?.map(c => c.value) || [];

          data = {
            ...data,
            users: userIds,
            groupId: groupId,
          };
        }
      } else if (keyword) {
        data = {
          contributionId: contribution?.id,
          keywords: keyword,
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
          groupId: groupId,
        };
      } else if (
        (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) ||
        (filters?.users?.length > 0 && !noneIsSelectedInUsersFilter)
      ) {
        data = {
          contributionId: contribution?.id,
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
          groupId: groupId,
        };
        if (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) {
          data = {
            ...data,
            taggs: filters?.taggs,
            groupId: groupId,
          };
        }

        if (filters?.users?.length > 0 && !noneIsSelectedInUsersFilter) {
          const userIds = filters?.users?.map(c => c.value) || [];

          data = {
            ...data,
            users: userIds,
            groupId: groupId,
          };
        }
      }
      if (data) {
        fetchSearchedPosts(data);
      } else {
        fetchPosts();
      }
    },
    [filterParams, fetchPosts, groupId],
  );

  const setFilterData = useCallback(
    data => {
      const newValues = {
        users: data?.byPeople,
        taggs: data?.byHashtag,
        communities: data?.byCommunity,
      };
      setFilterParams(newValues);
    },
    [groupId],
  );

  useEffect(() => {
    setPageNumber(1);
    if (currentGroup) {
      setTimeout(() => {
        dispatch(
          updateGroupUnreadCount.update({
            group: currentGroup,
            count: 0,
          }),
        );
      }, 800);
    }
  }, [currentGroup?.id, groupId]);

  const setCurrentGroup = () => {
    if (groupId && !groupId.includes('community') && subSections) {
      const groups = pluckKeys(subSections, 'groups');
      const allGroups = [].concat(...(groups ?? []), ...(archivedGroups ?? []));
      const group = allGroups.filter(value => value.id === groupId)[0];
      if (group) {
        dispatch(
          updateCurrentGroup.update({
            group: { ...group, unreadCount: 0 },
          }),
        );
        return;
      }
      resetCurrentGroup();
      history.replace(url.replace(`/${groupId}`, ''));
      return;
    }
    resetCurrentGroup();
  };
  useEffect(() => {
    setCurrentGroup();
  }, [groupId]);

  useEffect(() => {
    if (subSections && !sectionsRef.current) {
      setCurrentGroup();
      sectionsRef.current = true;
    }
  }, [subSections]);

  const fetchMoreData = () => {
    if (!!searchedWord || !noneIsSelectedInTaggs || !noneIsSelectedInUsers) {
      onKeywordClick({ keyword: selectedKeyWord, skip: communityPosts?.posts?.length });
    } else {
      dispatch(
        actions.getByPagePostsForContribution(
          `${contribution.id}/${communityPosts?.currentPage + 1}/${COUNT_POSTS_BY_PAGE}${
            !_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${groupId}` : ''
          }`,
        ),
      );
    }
  };
  const Loader = () => {
    return (
      <Grid container justifyContent="center" className="mb-4 mt-4">
        <CircularProgress color="primary" />
      </Grid>
    );
  };

  const backFunction = () => {
    setSupportPopup(false);
  };
  const useStyles = makeStyles(theme => ({
    MainDivStyled: {
      borderRadius: '14px',
    },
  }));
  const classes = useStyles();
  const isClient = currentRole === UserRoles.client;
  const clientPostEnabled = contribution.arePublicPostsAllowed || currentGroup?.enableClientPosting;

  const onSubmitFilter = useCallback(
    values => {
      const filtersDataForUnifiedCommunity = {
        taggs: values.byHashtag,
        communities: values.byCommunity,
      };

      const filtersDataForNormalCommunity = {
        taggs: values.byHashtag,
        users: values.byPeople,
      };

      const filterData = values.unifiedCommunity ? filtersDataForUnifiedCommunity : filtersDataForNormalCommunity;

      setFilterData(values);
      onKeywordClick({
        keyword: selectedKeyWord || '',
        skip: 0,
        params: filterData,
      });
    },
    [groupId],
  );

  const onHashtagClick = useCallback(
    hashtag => {
      const values = {
        byPeople: filterParams.users,
        byHashtag: [hashtag],
        byCommunity: filterParams.communities,
      };
      onSubmitFilter(values);
    },
    [filterParams],
  );

  const reloadPostsOnEdit = () => {
    setShowKeywordMenu(true);
    getHashTagByContributionId(contribution.id).then(res => {
      setHashtags(res);
    });
    onKeywordClick({
      keyword: searchedWord || '',
      skip: 0,
      params: filterParams,
    });
  };

  return (
    <StyledSocialContainer
      mobileView={mobileView}
      container
      className="contribution-social"
      direction="column"
      alignContent="center"
    >
      <Grid style={{ width: '95%' }} item md={12} xs={12}>
        {isLoad ? (
          supportPopup ? (
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline' }}>
              <Post modal={true} post={post} backFunction={backFunction} supportPopup={supportPopup} />
            </div>
          ) : (
            <>
              {showAllPostsTag && (
                <NewPosts
                  setShowAllPostsTag={setShowAllPostsTag}
                  unreadPostCount={totalUnreadPostCountAllCommunities}
                  colorToUse={colorToUse}
                  mobileView={mobileView}
                />
              )}
              <StyledWrap mobileView={mobileView} container style={{ marginBottom: '15px' }}>
                <Grid item xs={12} md={12} style={{ maxWidth: '530px' }} className={classes.MainDivStyled}>
                  <Grid container>
                    <SearchAndFilterSection
                      normalCommunity
                      onSearchChange={onSearchChange}
                      keywords={keywords}
                      searchedWord={searchedWord}
                      onKeywordClick={onKeywordClick}
                      setFilterData={setFilterData}
                      filterParams={filterParams}
                      keywordsLoaded={keywordsLoaded}
                      notificationsCount={notificationsCount}
                      contribution={contribution}
                      users={users}
                      hashtags={hashtags}
                      onCrossClick={onCrossClick}
                      onSubmitFilter={onSubmitFilter}
                      onClearFilter={onClearFilter}
                      isKeywordMenuOpen={isKeywordMenuOpen}
                      clearClicked={clearClicked}
                    />
                  </Grid>
                </Grid>
              </StyledWrap>
              {searchedWord?.length <= 0 && (
                <StyledWrap mobileView={mobileView} container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ maxWidth: '530px', marginBottom: '20px' }}
                    className={classes.MainDivStyled}
                  >
                    <Grid container>
                      <CreatePost item={communityPosts.userDraftPost} pods={pods} fetchPosts={reloadPostsOnEdit} />
                    </Grid>
                  </Grid>
                </StyledWrap>
              )}
              {!isKeywordMenuOpen ? (
                <>
                  {searchLoader ? (
                    <Loader />
                  ) : (
                    <InfiniteScroll
                      dataLength={communityPosts.posts.length}
                      next={fetchMoreData}
                      pullDownToRefreshThreshold={0}
                      hasMore={hasMorePosts}
                      loader={<Loader />}
                    >
                      <StyledWrap mobileView={mobileView} container className={`mb-4 ${clientPostEnabled && isClient}`}>
                        <Grid item xs={12} md={12}>
                          {(!!searchedWord ||
                            !noneIsSelectedInTaggs ||
                            (!isUnifiedCommunity && !noneIsSelectedInUsers) ||
                            (isUnifiedCommunity && !noneIsSelectedInCommunities)) &&
                          communityPosts?.posts?.length === 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                              }}
                            >
                              No search results found
                            </div>
                          ) : (
                            <PostList
                              setShowAllPostsTag={setShowAllPostsTag}
                              items={communityPosts.posts}
                              pods={pods}
                              searchedWord={searchedWord}
                              reloadPostsOnEdit={reloadPostsOnEdit}
                              onHashtagClick={onHashtagClick}
                            />
                          )}
                        </Grid>
                      </StyledWrap>
                    </InfiniteScroll>
                  )}
                </>
              ) : null}
            </>
          )
        ) : (
          <Loader />
        )}
      </Grid>
      <Modal
        title="Community Inaccessible"
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        You can not access community of this contribution.
      </Modal>
    </StyledSocialContainer>
  );
};

// ContributionSocialContainer.PropTypes = {
//   communityPosts: checkPropTypes.object.isRequired,
// };

const mapStateToProps = ({
  communityPosts,
  communitySections: { currentGroup, subSections, archivedGroups, mainGroup },
}) => ({
  communityPosts,
  currentGroup,
  subSections,
  archivedGroups,
  mainGroup,
});

export default connect(mapStateToProps)(ContributionSocialContainer);
