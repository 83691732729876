import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { connect, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from 'utils/styles';
import { useRouter, useHttp, useAccount } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { isEmpty } from 'lodash';
import { BodyText } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import { StyledLink } from '../../pages/CreateContribution/style';
import { List, Item } from './SidebarList';
import { ROUTES, TOOLTIP } from '../../constants.js';
import { CONTRIBUTION_COLORS, PAID_TIER_TITLES } from '../../constants';
import CoachImage from 'assets/chatlogo.png';
import { useDispatch } from 'react-redux';
import { UpdateUserProgressBarData } from 'services/user.service';
import * as contributionActions from 'actions/contributions';
import { updateUser } from 'actions/update-user';
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  Step,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { getLatestApproved } from 'services/contributions.service';
import { ContributionStatus } from 'helpers/constants';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 0 2rem 25px;
  margin-top: 10px;
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImgForTour = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const SetAsCompletedLink = styled.a`
  align-self: center;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      color: #ccc;
    `}
`;

function CreateContributionMenu({ archivingAllowed, errors, saveHandler = () => {}, duplicateIt, currentUserId }) {
  const { query, history } = useRouter();
  const { id, userId, deletingAllowed, status } = useContribution();
  const [asCompletedModal, setAsCompletedModal] = useState(false);
  const [asDeleteModal, setAsDeleteModal] = useState(false);
  const { request } = useHttp();
  const user = useAccount();
  const location = useLocation();

  const thankYouPage = useSelector(state => state.contributions.showThankYouPage);
  const [isRescheduleModalOpen, setRescheduleModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  const { state } = useLocation();
  const refreshStep = state?.from === 'refreshContribution';
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const isDraft = status === ContributionStatus.unfinished;
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const handleSubmitDelete = () => {
    setDeleteModal(false);
  };
  const handleSubmit = () => {
    setRescheduleModal(false);
  };
  const onSubmitHandler = id => {
    request('/Contribution/SetAsCompleted', 'POST', {
      contributionId: id,
    })
      .then(() => {
        setAsCompletedModal(false);
      })
      .then(() => history.push('/'))
      .catch(console.dir);
  };
  const onSubmitDeleteHandler = id => {
    request(`/Contribution/DeleteContribById/${id}`, 'DELETE')
      .then(() => {
        setAsDeleteModal(false);
      })
      .then(() => history.push('/'))
      .catch(console.dir);
  };
  const [run, setRun] = useState(false);
  let joyRideHelpers;
  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Setup Your Landing Page & Opt-In
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            In the following 5 simple steps, you are creating a landing page with built in paid or free opt-in that will
            bring your customers into your services on Cohere!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.FirstContributionDon === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'right',
      target: '#create-contribution-basic-info-link-test',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImgForTour previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Choose a Service Type
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Each service type has unique payment and scheduling features on Steps 3 & 4! You can change this at any time
            before you submit your service on Step 5.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#createContribution',
    },
  ]);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      dispatch(updateUser(u));
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(contributionActions.setPopUp(true));
        dispatch(contributionActions.setShowCompletedGetStart('create'));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null
          ? console.log('Hel00')
          : history.push(isDraft ? '/dashboard' : `contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };
  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  if (query.id) {
    return (
      <>
        <Container>
          <List menubar>
            <Item
              id="basic-info"
              title="1. Basic Information"
              to={`/edit-contribution/${query.id}/basic`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/basic`,
                })
              }
              isActive={location?.pathname.includes('/basic')}
              menubarItem
            />
            {isEmpty(errors) === true ? (
              <Item
                title="2. Details"
                to={`/edit-contribution/${query.id}/details`}
                onClick={event =>
                  saveHandler(event, {
                    to: `/edit-contribution/${query.id}/details`,
                  })
                }
                isActive={location?.pathname.includes('/details')}
                menubarItem
              />
            ) : (
              <Item
                title="2. Details"
                onClick={event => {
                  if (
                    (activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
                      activeContribution?.paymentInfo?.paymentOptions.includes('Free') &&
                      activeContribution?.type === 'ContributionCourse') ||
                    activeContribution?.type === 'ContributionOneToOne'
                  ) {
                    saveHandler(event, {
                      to: `/edit-contribution/${query.id}/details`,
                    });
                  } else {
                    dispatch(contributionActions.setErrorPopUp(true));
                  }
                }}
                isActive={location?.pathname.includes('/details')}
                menubarItem
              />
            )}
            {isEmpty(errors) === true ? (
              <Item
                title="3. Sessions"
                to={`/edit-contribution/${query.id}/sessions`}
                onClick={event =>
                  saveHandler(event, {
                    to: `/edit-contribution/${query.id}/sessions`,
                  })
                }
                isActive={location?.pathname.includes('/sessions')}
                menubarItem
              />
            ) : (
              <Item
                title="3. Sessions"
                onClick={event => {
                  if (
                    (activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
                      activeContribution?.paymentInfo?.paymentOptions.includes('Free') &&
                      activeContribution?.type === 'ContributionCourse') ||
                    activeContribution?.type === 'ContributionOneToOne'
                  ) {
                    saveHandler(event, {
                      to: `/edit-contribution/${query.id}/sessions`,
                    });
                  } else {
                    dispatch(contributionActions.setErrorPopUp(true));
                  }
                }}
                isActive={location?.pathname.includes('/sessions')}
                menubarItem
              />
            )}
            <Item
              title="4. Pricing"
              to={`/edit-contribution/${query.id}/payment`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/payment`,
                })
              }
              isActive={location?.pathname.includes('/payment')}
              menubarItem
            />
            <Item
              title="5. Review and Submit"
              to={`/edit-contribution/${query.id}/review`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/review`,
                })
              }
              isActive={location?.pathname.includes('/review')}
              menubarItem
            />
          </List>
          <ButtonContainer>
            {isEmpty(errors) ? (
              <Link
                to={isDraft ? '/dashboard' : `/contribution-view/${query.id}/about`}
                onClick={event => {
                  saveHandler(event, {
                    to: isDraft ? '/dashboard' : `/contribution-view/${query.id}/about`,
                  });
                }}
              >
                <Button
                  onClick={event => {
                    saveHandler(event, {
                      to: isDraft ? '/dashboard' : `/contribution-view/${query.id}/about`,
                    });
                  }}
                  disabled={thankYouPage}
                  autoWidth
                >
                  Save and Exit
                </Button>
              </Link>
            ) : (
              <Button
                onClick={event => {
                  if (query?.id) {
                    if (
                      (activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
                        activeContribution?.paymentInfo?.paymentOptions.includes('Free') &&
                        activeContribution?.type === 'ContributionCourse') ||
                      activeContribution?.type === 'ContributionOneToOne'
                    ) {
                      saveHandler(event, {
                        to: isDraft ? '/dashboard' : `/contribution-view/${query.id}/about`,
                      });
                    } else {
                      dispatch(contributionActions.setErrorPopUp(true));
                    }
                  }
                }}
                disabled={thankYouPage}
                autoWidth
              >
                Save and Exit
              </Button>
            )}
          </ButtonContainer>
          <ButtonContainer onClick={() => (!archivingAllowed ? setRescheduleModal(true) : setAsCompletedModal(true))}>
            <SetAsCompletedLink
              disabled={!archivingAllowed}
              href=""
              onClick={event => {
                archivingAllowed && setAsCompletedModal(true);
                event.preventDefault();
                return false;
              }}
            >
              Archive Service
            </SetAsCompletedLink>
            {!archivingAllowed && (
              <Tooltip
                title="You are allowed to archive if you have no outstanding sessions with clients or have no unused package sessions with clients . Once archived, this will appear in the “Archived” section under “My Services” on your dashboard. If you want to re-launch this service, you will need to create a new Service."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>
            )}
          </ButtonContainer>
          {currentUserId === userId ? (
            <ButtonContainer>
              <SetAsCompletedLink
                onClick={event => {
                  duplicateIt();
                  event.preventDefault();
                  return false;
                }}
                href=""
              >
                Duplicate Service
              </SetAsCompletedLink>
              <Tooltip
                title="Clicking this will duplicate this Service - everything in steps 1 & 2 will seamlessly transfer over to a new draft Service."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>
            </ButtonContainer>
          ) : null}
          {currentUserId === userId && (
            <ButtonContainer onClick={() => (!deletingAllowed ? setDeleteModal(true) : setAsDeleteModal(true))}>
              <SetAsCompletedLink
                disabled={!deletingAllowed}
                href=""
                onClick={event => {
                  // setAsDeleteModal(true);
                  event.preventDefault();
                  return false;
                }}
              >
                Delete Service
              </SetAsCompletedLink>
              {
                <Tooltip
                  title=" Since you have enrolled clients, you cannot delete this service as they will lose access. You can archive this service. Or, if you still wish to delete this service,please contact (support@cohere.live)."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor={colors.lightBrown} />
                </Tooltip>
              }
            </ButtonContainer>
          )}
        </Container>
        <Modal
          isOpen={asCompletedModal}
          title="Archive this Service"
          submitTitle="Yes I’m Sure. Archive my Service."
          onSubmit={() => {
            onSubmitHandler(query.id);
          }}
          onCancel={() => {
            setAsCompletedModal(false);
          }}
        >
          <p>
            Alert: Once you archive a Service, this cannot be undone. You are allowed to archive if you have no
            outstanding sessions with clients or have no unused package sessions with clients . Once archived, this will
            appear in the “Archived” section under “My Service” on your dashboard. If you want to re-launch this
            service, you will need to create a new Service”.
          </p>
        </Modal>
        <Modal
          isOpen={isRescheduleModalOpen}
          onCancel={() => {
            setRescheduleModal(false);
          }}
          onSubmit={handleSubmit}
          title="Archive Service"
          hiddenCancel
          disableConfirm
        >
          <BodyText>
            You are allowed to archive if you have no outstanding sessions with clients or have no unused package
            sessions with clients. Once archived, this will appear in the "Archived" section under "My Services" on your
            dashboard. If you want to re-launch this service, you will need to create a new Service.
          </BodyText>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onCancel={() => {
            setDeleteModal(false);
          }}
          onSubmit={handleSubmitDelete}
          title="Delete Service"
          brandingColor={false}
          hiddenCancel
          disableConfirm
        >
          <BodyText>
            Since you have enrolled clients, you cannot delete this service as they will lose access. You can archive
            this service. Or, if you still wish to delete this service, please contact{' '}
            <StyledLink href="support@cohere.live" rel="noopener noreferrer" target="_blank">
              (support@cohere.live).
            </StyledLink>{' '}
            <br />
          </BodyText>
        </Modal>
        <Modal
          isOpen={asDeleteModal}
          title="Delete Service"
          submitTitle="OK"
          brandingColor={false}
          onSubmit={() => {
            onSubmitDeleteHandler(id);
          }}
          onCancel={() => {
            setAsDeleteModal(false);
          }}
        >
          <p>
            Everything associated with this service will be deleted from your account. Are you sure you wish to
            continue?
          </p>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={0}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: CONTRIBUTION_COLORS.AccentColorCode,
            zIndex: 10000000,
          },
        }}
      />
      <Container>
        <div id="create-contribution-basic-info-link-test">
          <List menubar>
            <Item
              title="1. Basic Information"
              id="create-contribution-basic-info-link"
              to={ROUTES.CREATE_CONTRIBUTION_BASIC}
              onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_BASIC })}
              isActive={location?.pathname.includes('/basic')}
              menubarItem
            />
            <Item
              title="2. Details"
              id="create-contribution-details-link"
              to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_DETAILS}
              onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_DETAILS })}
              isActive={location?.pathname.includes('/details')}
              menubarItem
            />
            <Item
              title="3. Sessions"
              id="contribution-create-sessions-link"
              to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_SESSIONS}
              onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_SESSIONS })}
              isActive={location?.pathname.includes('/sessions')}
              menubarItem
            />
            <Item
              title="4. Pricing"
              id="create-contribution-pricing-link"
              to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_PAYMENT}
              onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_PAYMENT })}
              isActive={location?.pathname.includes('/payment')}
              menubarItem
            />
            <Item
              title="5. Review and Submit"
              id="create-contribution-review-and-submit-link"
              to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_REVIEW}
              onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_SAVE })}
              isActive={location?.pathname.includes('/review')}
              menubarItem
            />
          </List>
        </div>
        <ButtonContainer>
          <Link to="/dashboard" onClick={event => saveHandler(event, { to: '/dashboard' })}>
            <Button disabled={thankYouPage} autoWidth>
              Save and Exit
            </Button>
          </Link>
        </ButtonContainer>
      </Container>
    </>
  );
}

CreateContributionMenu.propTypes = {
  saveHandler: PropTypes.func,
};

CreateContributionMenu.defaultProps = {
  saveHandler: undefined,
};

const mapStateToProps = ({ contributions, account }) => ({
  archivingAllowed: contributions?.activeContribution?.archivingAllowed,
  currentUserId: account?.user?.id,
});

export default connect(mapStateToProps)(CreateContributionMenu);
