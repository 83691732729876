import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getCalendarColor, getEventsForCalendar } from 'utils/calendar';
import './MasterCalendarPage.scss';
import { useHeader } from 'hooks/usePageActions';
import * as pageActions from 'actions/page';
import { ClickAwayListener } from '@material-ui/core';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import { colors } from 'utils/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/FormUI/Button';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import * as calendarsActions from 'actions/calendars';
import { darken } from 'polished';
import AddIcon from '@mui/icons-material/Add';
import Loader from 'components/UI/Loader';
import ScheduleMeetingModal from 'pages/ContributionView/components/CalendarContributionView/ScheduleMeetingModal';
import {
  fetchCohealerContribution,
  fetchContributionActions,
  fetchContributionTimes,
  fetchContributionTimesActions,
  fetchCustomContributionTimes,
  fetchCustomContributionTimesActions,
  rescheduleSession as rescheduleSessionAction,
  setFetchSlotsCalendar,
} from 'actions/contributions';
import { getCalendars, getMasterCalendarEvents } from 'services/calendars.service';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import ConfirmationModal from 'pages/ContributionView/ContributionCommunity/components/ConfirmationModal';
import RefuseModal from 'pages/ContributionView/components/RefuseModal';
import { formatTime } from 'utils/utils';
import DateNavigationComponent from './components/DateNavigator';
import ExternalCalendarVisibilityComponent from './components/ExternalCalendarVisibility';
import ReschduleSessionPopup from './components/ReschedulePopup';
import ExternalCalendarSettingsPopup from './components/ExternalCalendarSettingsPopup';
import EventsPopup from './components/EventsPopup';
import { ContributionType } from 'helpers/constants';
import { redirectTo } from 'services/links';
import { AvailabilityModal } from './components/availabilityModal';
import CustomEvent from './components/CustomEventsUI';
import { isArray, isEmpty, uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getCohealerContribution } from 'services/contributions.service';
import Tooltip from '@mui/material/Tooltip';
import EasyBooking from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/EasyBooking';
import SessionBookingThankyou from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/components/SessionBookingThankyou';
import { Box, MenuItem, Select, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  addToCalendarAction,
  extraCalendarSettingAction,
  oneToOneAvailabilityAction,
  removeMasterCalendarEmailAction,
  scheduleClientAction,
  setPreSelectedMasterCalendarDate,
  toggleMasterCalendarSideBar,
} from 'actions/masterCalendarSidebar.actions';
import * as calendarAction from 'actions/calendars';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { checkIsPast, getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import styled from 'styled-components';
import MyCustomEvent from './components/CustomAvailabilityEvents';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { setDefaultWeeklyData } from 'actions/oneToOneData';
import { MasterCalendarAvailabilityModal } from './components/MasterCalendarAvailabilityModal';

const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);
const viewTabButtonsData = ['Day', 'Week', 'Month'];
const StyledEditIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
`;

const StyledRecurringIcon = styled.div`
  padding-left: 5px;
`;

const StyledCloseIcon = styled.div`
  cursor: pointer;
`;

const StyledOption = styled.div`
  width: 100%;
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  border-radius: 0px;
  padding: 16px 20px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '2px solid #e7e7e7' : '')};
  ${({ disabled }) =>
    disabled &&
    `
  cursor: default;
  color: darkgray;
  `}
`;

const DropDown = styled.div`
  width: ${({ w }) => w};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const DROPDOWN_WITH = 200;
const MasterCalendarPage = ({
  addCalendar,
  calendars,
  fetchCalendars,
  removeCalendar,
  calendarActiveView,
  isloading,
  rescheduleChosenSession,
  calendarSignInCallback,
}) => {
  useHeader('Calendar');
  const query = new URLSearchParams(useLocation().search);
  const {
    updateOneToOneAvailability,
    addToCalendar,
    scheduleClient,
    extraCalendarSetting,
    removeMasterCalendarEmail,
    previewMasterCalendarEvent,
  } = useSelector(state => state.masterCalendar.sideBarActions);
  const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
  const { fetchSlotsCalendarLoader, contributionTimes, activeContribution } = useSelector(state => state.contributions);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [showDropDown, setShowDropDown] = useState(false);
  const [eventClicked, setEventClicked] = useState(null);
  const code = useMemo(() => query.get('code'), [query]);
  const [showLoader, setShowLoader] = useState(true);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [showSelfbookModal, setShowSelfbookModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showExternalCalendarSettingsModal, setShowExternalCalendarSettingsModal] = useState(false);
  const [currentCalendarView, setCurrentCalendarView] = useState('Month');
  const [
    showExternalCalendarConnectionCompleteStepsModal,
    setShowExternalCalendarConnectionCompleteStepsModal,
  ] = useState({
    showStep1: false,
    showStep2: false,
  });
  const { manageAvailabilitySelection, selectedMasterCalendarContribution } = useSelector(
    state => state.masterCalendar,
  );

  const [calendarForSettingsPopup, setCalendarForSettingsPopup] = useState(false);
  const [isSessionLoading, setIsSessionLoading] = useState(isloading);
  const [showMasterCalendarAvailabilityPopUp, setShowMasterCalendarAvailabilityPopUp] = useState(false);
  const [showAvailabilityPopUp, setshowAvailabilityPopUp] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rescheduledSession, setRescheduledSession] = useState(null);
  const [externalCalendarEmail, setExternalCalendarEmail] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventsAvailability, setEventsAvailability] = useState([]);
  const [contribution, setContribution] = useState(null);
  const [showCustomMessageModal, setShowCustomMessageModal] = useState(false);
  const [showSuccessfulReschedulePop, setShowSuccessfulReschedulePop] = useState(false);
  const [showUnsuccessfulReschedulePop, setShowUnsuccessfulReschedulePop] = useState(false);
  const [isRescheduleLoading, setIsRescheduleLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [messageText, setMessageText] = useState('');
  const [prevView, setPrevView] = useState('');
  const [showEventsPopup, setShowEventsPopup] = useState(false);
  const [showExternalCalendarEventPopup, setShowExternalCalendarEventPopup] = useState(false);
  const [currentViewDateRange, setCurrentViewDateRange] = useState('');
  const [selectedDateEvents, setSelectedDateEvents] = useState({
    date: new Date(),
    events: [],
  });
  const dispatch = useDispatch();
  const [fetchEvents, setfetchEvents] = useState(false);
  const contributionsForTable = useSelector(s => s?.upcomingCreated?.upcomingCreated?.contributionsForTable || null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedEmailToRemove, setSelectedEmailToRemove] = useState('');
  const [openSettings, setOpenSettings] = useState(false);
  const { lastAddedEmail, loadingLastEmail } = useSelector(s => s.calendars);
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const handleSlotClick = slot => {
    if (slot?.start) {
      if (checkIsPast(slot.start) === false) {
        dispatch(setPreSelectedMasterCalendarDate(slot.start));
      }
    }

    if (manageAvailabilitySelection === 'booking-availability') {
      setShowMasterCalendarAvailabilityPopUp(true);
    } else {
      dispatch(oneToOneAvailabilityAction(true));
      dispatch(setDefaultWeeklyData());
    }
  };

  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);

  const refetchEvents = () => {
    fetchCalendars().then(() => {
      handleStartAndEndDateOfView(currentViewDateRange, '', true);
      setfetchEvents(false);
    });
  };

  const handleOutsideClick = e => {
    if (!e.defaultPrevented) {
      setShowDropDown(false);
      setEventClicked(null);
    }
  };
  const handleOneToOneAvailability = () => {
    setshowAvailabilityPopUp(true);
    setIsSessionLoading(false);
  };

  const handleAddMyCalendar = () => {
    if (isArray(calendars) && calendars.length) {
      setIsAddCalendarModalOpen(true);
    } else {
      dispatchAddToCalendarAction();
      addCalendar({ contributionId: null, isCreate: true, isMasterCalendar: true });
    }
  };
  const selectEventHandler = (event, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    console.log('slot---->11', event);
    setEventClicked(event.start);
    dispatch(setPreSelectedMasterCalendarDate(event.start)); //
    const ele = document.getElementById('calendarmain');
    ele.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    let clientX;
    let clientY;
    const rect = ele.getBoundingClientRect();
    clientX = e?.clientX || event?.box?.clientX || event?.bounds?.x;
    clientY = e?.clientY || event?.box?.clientY || event?.bounds?.y;
    const offsetForMobile = event?.bounds && mobileView ? window.scrollY : 0;
    clientX = clientX - rect.left;
    clientY = clientY - rect.top - offsetForMobile;

    const spaceLeft = clientX;
    const spaceRight = window.innerWidth - clientX - 270;

    // Open on the right side by default
    if (spaceRight >= DROPDOWN_WITH) {
      clientX = clientX;
      clientY = clientY;
    } else if (spaceLeft >= DROPDOWN_WITH) {
      // If there's not enough space on the right, switch to the left
      clientX = clientX - DROPDOWN_WITH;
      clientY = clientY;
    } else {
      // If there's not enough space on either side, prioritize right
      clientX = clientX;
      clientY = clientY;
    }

    // const { clientX, clientY } = e || event?.box;
    setDropdownPosition({ x: clientX, y: clientY });
    if (e) {
      // setAvailabilityDisabled(true);
    } else {
      // setAvailabilityDisabled(false);
    }

    setShowDropDown(true);
  };
  const handleOnPreviewIconClick = (calendar, isVisible, view) => {
    setIsSessionLoading(true);
    getMasterCalendarEvents({
      isPreviewMasterCalendarEvents: isVisible,
      email: calendar?.emailAddress,
    })
      .then(() => {
        fetchCalendars().then(() => {
          handleStartAndEndDateOfView(currentViewDateRange, view, true);
        });
      })
      .finally(() => {
        setIsSessionLoading(false);
      });
  };

  // useEffect(() => {
  //   if (currentViewDateRange && fetchEvents && manageAvailabilitySelection != 'booking-availability') {
  //     // refetchEvents();
  //     // handleStartAndEndDateOfView(currentViewDateRange, '', true);
  //   }
  // }, [currentViewDateRange, fetchEvents]);

  // ** Calendar View Select UseEffect //

  // useEffect(() => {
  //   if (manageAvailabilitySelection === 'booking-availability') {
  //   } else {
  //     setCurrentCalendarView(calendarActiveView);
  //     // refetchEvents();
  //     // handleStartAndEndDateOfView(currentViewDateRange, '', true);
  //   }
  // }, [calendarActiveView]);

  // ** Master Calendar Sidebar Actions Handle

  useEffect(() => {
    dispatch(calendarAction.setCurrentCalendarView.request('Month'));
  }, [manageAvailabilitySelection]);

  const dispatchAddToCalendarAction = () => {
    dispatch(addToCalendarAction(false));
  };
  const dispatchScheduleClientAction = () => {
    dispatch(scheduleClientAction(false));
  };

  const dispatchExtraCalendarSettingAction = () => {
    dispatch(extraCalendarSettingAction(null));
  };

  useEffect(() => {
    dispatch(toggleMasterCalendarSideBar(true));
  }, []);

  useEffect(() => {
    if (updateOneToOneAvailability) {
      handleOneToOneAvailability();
    }
  }, [updateOneToOneAvailability]);

  useEffect(() => {
    if (addToCalendar) {
      handleAddMyCalendar();
    }
  }, [addToCalendar]);

  useEffect(() => {
    if (scheduleClient) {
      setShowSelfbookModal(true);
    }
  }, [scheduleClient]);

  useEffect(() => {
    if (!!extraCalendarSetting) {
      setCalendarForSettingsPopup(extraCalendarSetting);
      dispatchAddToCalendarAction();
      setIsAddCalendarModalOpen(false);
      setShowExternalCalendarSettingsModal(true);
    }
  }, [extraCalendarSetting]);

  useEffect(() => {
    if (!!removeMasterCalendarEmail) {
      setSelectedEmailToRemove(removeMasterCalendarEmail);
      setIsConfirmationModalOpen(true);
    }
  }, [removeMasterCalendarEmail]);

  useEffect(() => {
    if (!!previewMasterCalendarEvent) {
      handleOnPreviewIconClick(
        previewMasterCalendarEvent,
        previewMasterCalendarEvent?.isPreviewMasterCalendarEvents,
        prevView,
      );
    }
  }, [previewMasterCalendarEvent]);

  // **

  useEffect(() => {
    if (code) {
      calendarSignInCallback(code, true).then(() => {
        setfetchEvents(true);
        query.delete('code');
        history.replace({
          search: query.toString(),
        });
      });
    }
  }, [code]);
  // useEffect(() => {
  //   if (code) {
  //     // TODO: here open the popup after connecting 1st calendar to complete setup
  //     if (isArray(calendars) && calendars.length === 1) {
  //       setCalendarForSettingsPopup(calendars.at(0));
  //       setShowExternalCalendarConnectionCompleteStepsModal({
  //         ...showExternalCalendarConnectionCompleteStepsModal,
  //         showStep1: true,
  //         showStep2: false,
  //       });
  //       query.delete('code');
  //       history.replace({
  //         search: query.toString(),
  //       });
  //     }
  //   }
  // }, [calendars]);
  useEffect(() => {
    if (!loadingLastEmail && lastAddedEmail) {
      // TODO: here open the popup after connecting 1st calendar to complete setup
      if (isArray(calendars) && calendars.length) {
        const recentAddedAccount = calendars.find(k => k.emailAddress === lastAddedEmail);
        if (recentAddedAccount) {
          setCalendarForSettingsPopup(recentAddedAccount);
          setShowExternalCalendarConnectionCompleteStepsModal({
            ...showExternalCalendarConnectionCompleteStepsModal,
            showStep1: true,
            showStep2: false,
          });
        }
      }
    }
  }, [calendars, lastAddedEmail, loadingLastEmail]);
  // useEffect(() => {
  //   if (code) {
  //     // TODO: here open the popup after connecting 1st calendar to complete setup
  //     if (isArray(calendars) && calendars.length === 1) {
  //       setCalendarForSettingsPopup(calendars.at(0));
  //       setShowExternalCalendarConnectionCompleteStepsModal({
  //         ...showExternalCalendarConnectionCompleteStepsModal,
  //         showStep1: true,
  //         showStep2: false,
  //       });
  //       query.delete('code');
  //       history.replace({
  //         search: query.toString(),
  //       });
  //     }
  //   }
  // }, [calendars]);

  useEffect(() => {
    if (!showSelfbookModal && !isRescheduleModalOpen) {
      dispatch(fetchContributionActions.success(null));
    }
  }, [showSelfbookModal, isRescheduleModalOpen]);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const onRescheduleSlotSelection = slot => {
    setSelectedSlot(slot);
    setShowCustomMessageModal(true);
    setShowRescheduleModal(false);
  };

  const onEventClick = event => {
    if (event.contributionType === ContributionType.contributionOneToOne) {
      redirectTo(`/contribution-view/${event?.contributionId}/sessions?sessionId=${event.id || event.sessionId}`);
    } else if (event?.isExternalEvent) {
      let calendarEmail = calendars.filter(item => item.colorCode === event.color);
      if (calendarEmail.length > 0) {
        setExternalCalendarEmail(calendarEmail[0].emailAddress);
      } else {
        setExternalCalendarEmail(null);
      }
      setTimeout(() => {
        setShowExternalCalendarEventPopup(true);
      }, 1000);
    } else if (event.contributionType !== ContributionType.contributionOneToOne && event?.contributionId) {
      redirectTo(`/edit-contribution/${event?.contributionId}/sessions`);
    }
  };

  const handleStartAndEndDateOfView = (range, view, externalCalendarUpdate = false) => {
    if (view === prevView && !externalCalendarUpdate) return;
    setPrevView(view || prevView);
    let viewStartDate;
    let viewEndDate;
    if (Array.isArray(range)) {
      // Handle the case when range is an array of Date objects in week and day view
      viewStartDate = range[0];
      viewEndDate = range[range.length - 1];
    } else if (typeof range === 'object' && range?.start && range?.end) {
      // Handle the case when range is an object with start and end properties usually in month view
      viewStartDate = range.start;
      viewEndDate = range.end;
    }
    setCurrentViewDateRange(range);
    viewEndDate = new Date(viewEndDate?.getTime());
    setIsSessionLoading(true);
    getCalendars().then(res => {
      const updatedCalendars =
        res.length > 0 &&
        res?.map(calendar => {
          const { calendarId, emailAddress, subCalendars, colorCode } = calendar;
          const updatedSubCalendars = subCalendars.map(subCalendar => ({
            ...subCalendar,
            calendarId,
            emailAddress,
            colorCode,
          }));
          return { ...calendar, subCalendars: updatedSubCalendars };
        });
      getEventsForCalendar({ viewStartDate, viewEndDate, contributionId: null, updatedCalendars })
        .then(allEvents => {
          if (allEvents?.length === 0) {
            setShowLoader(false);
          }
          if (manageAvailabilitySelection != 'booking-availability') {
            setEvents(allEvents);
            if (allEvents.length > 0) {
              setShowLoader(true);
            } else {
              setShowLoader(false);
            }
          }
        })
        .finally(() => {
          setIsSessionLoading(false);
        });
    });
  };

  const onReschedule = () => {
    const { offset, id: rescheduleToId } = selectedSlot;
    const { contributionId, id: rescheduleFromId } = rescheduledSession;

    setIsRescheduleLoading(true);
    rescheduleChosenSession({
      contributionId,
      note: messageText,
      offset,
      rescheduleFromId,
      rescheduleToId,
    })
      .then(data => {
        if (typeof data?.payload === 'string' && data.payload.includes('already booked')) {
          setShowUnsuccessfulReschedulePop(true);
        } else {
          handleStartAndEndDateOfView(currentViewDateRange, '', false);
          setShowSuccessfulReschedulePop(true);
        }
      })
      .finally(() => {
        setShowCustomMessageModal(false);
        setShowEventsPopup(false);
        setIsRescheduleLoading(false);
        setMessageText(null);
      });
  };

  const isDisabled =
    contributionsForTable?.filter(
      contribution => contribution.type === 'ContributionOneToOne' && contribution.status == 'Approved',
    ).length === 0;

  const CustomToolbar = toolbar => {
    return (
      <span>
        <div className="custom-header-calendar">
          {mobileView && (
            <Box
              onClick={() => {
                dispatch(pageActions.toggleMobileSidebar());
                dispatch(toggleMasterCalendarSideBar(true));
              }}
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}
            >
              <ArrowBackIosIcon fontSize="15px" style={{ color: '#215C73' }} />{' '}
              <Typography style={{ color: '#215C73', fontSize: '11px', fontWeight: 800 }}>
                Schedule & Settings
              </Typography>
            </Box>
          )}
          <DateNavigationComponent isShortLabel={false} toolbar={toolbar} calendarActiveView={calendarActiveView} />
          {mobileView && (
            <div>
              <Select
                style={{ width: '90px' }}
                variant="outlined"
                displayEmpty
                className="calender-current-view-select"
                value={currentCalendarView}
                MenuProps={{
                  PaperProps: {
                    style: { fontFamily: 'Avenir' },
                  },
                }}
                onChange={() => {}}
              >
                {viewTabButtonsData.map(value => (
                  <MenuItem
                    value={value}
                    onClick={() => {
                      dispatch(calendarAction.setCurrentCalendarView.request(value));
                      setCurrentCalendarView(value);
                      setShowDropDown(false);
                    }}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      </span>
    );
  };

  useEffect(() => {
    if (manageAvailabilitySelection === 'booking-availability') {
      if (selectedMasterCalendarContribution != null) {
        setShowLoader(true);
        dispatch(fetchContributionTimes(selectedMasterCalendarContribution, true));
        dispatch(fetchCohealerContribution(selectedMasterCalendarContribution));
      } else {
        setShowLoader(false);
      }
    } else {
      dispatch(fetchCustomContributionTimesActions.success(null));
      dispatch(fetchContributionTimesActions.success(null));
      dispatch(fetchContributionActions.success(null));
      // refetchEvents();
      // handleStartAndEndDateOfView(currentViewDateRange, '', true);
    }
  }, [manageAvailabilitySelection, selectedMasterCalendarContribution]);
  useEffect(() => {
    if (manageAvailabilitySelection === 'booking-availability') {
      if (masterCalendarArray?.length === 1) {
        dispatch(
          fetchCustomContributionTimes(
            selectedMasterCalendarContribution,
            activeContribution?.oneToOneSessionDataUi?.sessionDuration,
            activeContribution?.oneToOneSessionDataUi?.sessionDuration,
          ),
        );
      } else {
        dispatch(fetchCustomContributionTimesActions.success(null));
      }
    }
  }, [activeContribution, masterCalendarArray]);

  useEffect(() => {
    if (selectedMasterCalendarContribution != null) {
      setEventsAvailability([]);
      if (
        manageAvailabilitySelection === 'booking-availability' &&
        contributionTimes != null &&
        contributionTimes.length > 0
      ) {
        const events = getTimePeriodsForAvailability({
          availabilityPeriods: uniqBy(contributionTimes, 'id'),
          duration: '60',
          serviceProviderName: 'test',
        });
        if (events.length > 0 && manageAvailabilitySelection != 'booking-availability') {
          setShowLoader(true);
        } else {
          setShowLoader(false);
        }
        setEventsAvailability(events);
        dispatch(setFetchSlotsCalendar(false));
      }
    }
  }, [activeContribution, contributionTimes, selectedMasterCalendarContribution]);
  return (
    <div id="calendarmain" style={{ position: 'relative', height: '100%' }}>
      <ClickAwayListener onClickAway={handleOutsideClick}>
        <div style={{ padding: mobileView ? '20px 0px' : '20px' }} className="master-calendar-container">
          {(isSessionLoading || isloading || isRescheduleLoading || fetchSlotsCalendarLoader || showLoader) && (
            <Loader />
          )}
          {manageAvailabilitySelection === 'booking-availability' && contributionTimes != null ? (
            <BigCalendar
              className={`cohere-master-calendar custom-calendar custom-${prevView}-calendar-view`}
              style={{ color: 'black', overflow: 'auto' }}
              localizer={localizer}
              events={eventsAvailability}
              views={allViews}
              defaultView={Views.DAY}
              components={{
                toolbar: CustomToolbar,
                event: props =>
                  MyCustomEvent({ ...props, activeView: calendarActiveView, setShowLoader: setShowLoader }),
              }}
              step={30}
              showMultiDayTimes
              defaultDate={new Date()}
              onShowMore={() => {
                dispatch(calendarAction.setCurrentCalendarView.request('Day'));
                setCurrentCalendarView('Day');
                setShowDropDown(false);
                setTimeout(() => {
                  // Scroll to 9 AM when navigating to the Day view
                  const calendar = document.getElementsByClassName('rbc-event')[0];
                  if (calendar) {
                    calendar.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }, 200);
              }}
              startAccessor="start"
              endAccessor="end"
              onSelectSlot={handleSlotClick}
              selectable
              formats={{ eventTimeRangeFormat: () => '' }}
              eventPropGetter={({ color }) => {
                const newStyle = {
                  backgroundColor: color || colors.darkOceanBlue,
                  // minHeight: 24,
                  fontSize: Views.MONTH !== calendarActiveView?.toLowerCase() && 14,
                  // position: 'relative',
                  ...(Views.MONTH !== calendarActiveView?.toLowerCase() && { paddingTop: 0, paddingBottom: 0 }),
                };

                return {
                  className: '',
                  style: newStyle,
                };
              }}
            />
          ) : (
            <BigCalendar
              className={`cohere-master-calendar custom-calendar custom-${prevView}-calendar-view`}
              style={{ color: 'black', overflow: 'auto' }}
              localizer={localizer}
              events={events}
              views={allViews}
              defaultView={Views.DAY}
              step={30}
              showMultiDayTimes
              defaultDate={new Date()}
              startAccessor="start"
              endAccessor="end"
              onSelectSlot={selectEventHandler}
              selectable
              onShowMore={(dateEvents, date) => {
                setSelectedDateEvents({
                  date,
                  events: dateEvents,
                });
                setShowDropDown(false);
                setShowEventsPopup(true);
              }}
              onDrillDown={() => {}}
              onView={view => {
                if (view === 'day' || view === 'week') {
                  setTimeout(() => {
                    // Scroll to 9 AM when navigating to the Day view
                    const calendar = document.getElementsByClassName('rbc-event')[0];
                    if (calendar) {
                      calendar.scrollTo({
                        top: 640,
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }
              }}
              formats={{ eventTimeRangeFormat: () => '', dayFormat: 'MMM D' }}
              components={{
                toolbar: CustomToolbar,
                event: event => CustomEvent({ ...event, view: prevView, setShowLoader: setShowLoader }),
              }}
              onRangeChange={handleStartAndEndDateOfView}
              onSelectEvent={onEventClick}
              eventPropGetter={(
                { color, start: eventStart, contributionType, isExternalEvent },
                start,
                end,
                isSelected,
              ) => {
                const isPastEvent = moment(eventStart).isBefore(moment(), 'day');
                const nonMonthProps = {
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  borderRadius: '0',
                  borderLeft: `4px solid ${darken(0.1, color)}`,
                  borderTop: `1px solid ${darken(0.1, color)}`,
                  borderTopRightRadius: '4px',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  paddingTop: '5px',
                };
                let newStyle = {
                  color: color || colors.darkOceanBlue,
                  backgroundColor: 'rgba(0,0,0,0)',
                  minHeight: 24,
                  fontSize: 14,
                  fontWeight: 500,
                  cursor: 'pointer',
                  outline: isSelected && (isPastEvent || !contributionType) ? 'none' : 'inherit',
                };
                if (prevView !== 'month') {
                  newStyle = {
                    ...newStyle,
                    ...nonMonthProps,
                  };
                }
                return {
                  className: '',
                  style: newStyle,
                };
              }}
            />
          )}
          {isAddCalendarModalOpen && (
            <AddCalendarModal
              isOpen
              onModalClose={() => {
                dispatchAddToCalendarAction();
                setIsAddCalendarModalOpen(false);
              }}
              onSubmit={() => {
                dispatchAddToCalendarAction();
                setIsAddCalendarModalOpen(false);
              }}
              addCalendar={addCalendar}
              calendars={calendars}
              showsetInviteCalendarRow="false"
              integrationPage
              isMasterCalendar
              setCalendarForSettingsPopup={setCalendarForSettingsPopup}
              setShowExternalCalendarSettingsModal={setShowExternalCalendarSettingsModal}
            />
          )}
          <Modal
            height={'650px'}
            isOpen={isRescheduleModalOpen}
            onCancel={() => {
              setRescheduleModalState(false);
            }}
            onSubmit={() => {
              setRescheduleModalState(false);
            }}
            title="Reschedule your session"
            noPadding
            isBookingModal
            isDarkModeSelected={false}
            isCreatingContribution
            disableFooter
            CrossIconHide={!rescheduleSession}
          >
            {rescheduleSession ? (
              <EasyBooking
                clientPreviewMode={false}
                isSelfSchedule
                rescheduleSession={rescheduleSession}
                setRescheduleSession={setRescheduleSession}
                isModal
                isCoach
                isMasterCalendar
              />
            ) : (
              <SessionBookingThankyou
                setRescheduleSession={setRescheduleSession}
                rescheduleSession={rescheduleSession}
                isModal
                isMasterCalendar
                handleCancel={() => setRescheduleModalState(false)}
              />
            )}
          </Modal>
          {showSelfbookModal && (
            <ScheduleMeetingModal
              key={showSelfbookModal}
              isVisible={showSelfbookModal}
              closeModal={() => {
                setShowSelfbookModal(false);
                dispatchScheduleClientAction();
              }}
              onMasterCalendarComplete={() => refetchEvents()}
              setRescheduleSession={() => {
                setRescheduleSession(true);
                setRescheduleModalState(true);
                setShowSelfbookModal(false);
                dispatchScheduleClientAction();
              }}
              title="Create Session"
              isCoach
              paymentInfo={contribution?.paymentInfo}
              setIsScheduleMeetingVisible={value => {
                setShowSelfbookModal(value);
                dispatchScheduleClientAction();
              }}
              selectedCalDate={selectedDate}
              fromMasterCalendar
              isMasterCalendar
            />
          )}
          {showRescheduleModal && (
            <ReschduleSessionPopup
              isModalOpen={showRescheduleModal}
              session={rescheduledSession}
              onCloseModal={() => setShowRescheduleModal(false)}
              onConfirm={onRescheduleSlotSelection}
              contribution={contribution}
            />
          )}
          {(showExternalCalendarSettingsModal ||
            showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
            showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
            <ExternalCalendarSettingsPopup
              isModalOpen={
                showExternalCalendarSettingsModal ||
                showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
                showExternalCalendarConnectionCompleteStepsModal.showStep2
              }
              // connectedCalendars={calendars}
              connectedCalendar={calendarForSettingsPopup}
              onCloseModal={() => {
                setCalendarForSettingsPopup(null);
                setShowExternalCalendarSettingsModal(false);
                dispatchExtraCalendarSettingAction();
                setShowExternalCalendarConnectionCompleteStepsModal({
                  ...showExternalCalendarConnectionCompleteStepsModal,
                  showStep1: false,
                  showStep2: false,
                });
              }}
              setShowExternalCalendarConnectionCompleteStepsModal={setShowExternalCalendarConnectionCompleteStepsModal}
              showExternalCalendarConnectionCompleteStepsModal={showExternalCalendarConnectionCompleteStepsModal}
              isMasterCalendarPage
              onSuccessSave={emailAddress => {
                setIsSessionLoading(true);
                fetchCalendars()
                  .then(() => {
                    handleStartAndEndDateOfView(currentViewDateRange, prevView, true);
                  })
                  .finally(() => {
                    setIsSessionLoading(false);
                  });
              }}
            />
          )}
          {showCustomMessageModal && (
            <Modal
              isOpen={showCustomMessageModal}
              onCancel={() => setShowCustomMessageModal(false)}
              onSubmit={onReschedule}
              title="Confirm your new session time"
              hiddenCancel
              submitTitle="Confirm Reschedule"
              widthRequired="850px"
              brandingColor={false}
              isCreatingContribution={true}
            >
              <BodyText style={{ overflow: 'hidden' }} className="test">
                By clicking Confirm, you will be updating the session time with {rescheduledSession?.clientName}. It’s
                your responsibility to confirm with {rescheduledSession?.clientName} that this new time also works for
                them.
              </BodyText>
              <TextField
                style={{ width: '100%' }}
                placeholder="Optional. What is the reason for rescheduling? This information will be shared with your client"
                multiline
                rowsMax={6}
                value={messageText}
                onChange={e => setMessageText(e.target.value)}
                className="rescheduleResonInput"
              />
            </Modal>
          )}
          {showSuccessfulReschedulePop && (
            <Modal
              isOpen={showSuccessfulReschedulePop}
              onCancel={() => setShowSuccessfulReschedulePop(false)}
              onSubmit={() => setShowSuccessfulReschedulePop(false)}
              title="Reschedule Successful"
              width="800px"
              widthRequired="800px"
              hiddenCancel
              brandingColor={false}
              isCreatingContribution={true}
            >
              <BodyText style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: '500px' }}>
                Session has been successfully reschedule on{' '}
                <span style={{ color: '#C9B382' }}>
                  {' '}
                  {formatTime(selectedSlot.start)}, {moment(selectedSlot.start).format('hh:mm A')}{' '}
                </span>
              </BodyText>
            </Modal>
          )}
          {showUnsuccessfulReschedulePop && (
            <RefuseModal
              className="master-calendar-refuse-model"
              isOpen
              onClose={() => setShowUnsuccessfulReschedulePop(false)}
              onSubmit={() => setShowUnsuccessfulReschedulePop(false)}
              message="Slot already booked"
            />
          )}
          {showEventsPopup && (
            <EventsPopup
              events={selectedDateEvents.events}
              date={selectedDateEvents.date}
              onEventClick={onEventClick}
              onCancel={() => setShowEventsPopup(false)}
            />
          )}
          {showExternalCalendarEventPopup && (
            <Modal
              className="external-event-popup"
              isOpen={showExternalCalendarEventPopup}
              onCancel={() => {
                setShowExternalCalendarEventPopup(false);
                setExternalCalendarEmail(null);
              }}
              title="External Calendar Event"
              cancelTitle="Close"
              disableConfirm
              brandingColor={false}
              isCreatingContribution={true}
              cancelBtnStyle={{ color: 'white' }}
            >
              <p>
                This event was created in your external calendar
                {externalCalendarEmail === null ? '' : ` (${externalCalendarEmail})`}. To make any changes, please
                update it directly in your external calendar.
              </p>
            </Modal>
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isOpen
              title="Confirm Action"
              submitTitle="Yes, Delete"
              cancelTitle="No, Cancel"
              onSubmit={() => {
                removeCalendar(selectedEmailToRemove).then(() => {
                  refetchEvents();
                });
                setIsConfirmationModalOpen(false);
                setCalendarForSettingsPopup(null);
                setShowExternalCalendarSettingsModal(false);
                dispatchExtraCalendarSettingAction();
                setShowExternalCalendarConnectionCompleteStepsModal({
                  ...showExternalCalendarConnectionCompleteStepsModal,
                  showStep1: false,
                  showStep2: false,
                });
              }}
              onCancel={() => {
                dispatch(removeMasterCalendarEmailAction(''));
                setSelectedEmailToRemove('');
                setIsConfirmationModalOpen(false);
              }}
            >
              <p style={{ marginBottom: '10px' }}>
                <strong>Are you sure you want to DELETE this integrated calendar?</strong>
              </p>
              <span>If you remove this calendar, it will no longer be integrated with your availability.</span>
            </ConfirmationModal>
          )}
          {showAvailabilityPopUp && (
            <AvailabilityModal
              showTerms={showAvailabilityPopUp}
              setshowAvailabilityPopUp={action => {
                dispatch(oneToOneAvailabilityAction(false));
                setshowAvailabilityPopUp(action);
              }}
              onCancel={() => {
                dispatch(oneToOneAvailabilityAction(false));
                setshowAvailabilityPopUp(false);
                // setshowAvailabilityPopUp = { setshowAvailabilityPopUp };
              }}
              incrementNewSlots={() => {}}
            />
          )}

          {showMasterCalendarAvailabilityPopUp && (
            <MasterCalendarAvailabilityModal
              showTerms={showMasterCalendarAvailabilityPopUp}
              setshowAvailabilityPopUp={action => {
                setShowMasterCalendarAvailabilityPopUp(false);
              }}
              onCancel={() => {
                setShowMasterCalendarAvailabilityPopUp(false);
              }}
              incrementNewSlots={() => {}}
            />
          )}
        </div>
      </ClickAwayListener>
      {showDropDown && (
        <DropDown top={dropdownPosition.y} left={dropdownPosition.x} w={`${DROPDOWN_WITH}px`}>
          <StyledOption
            disabled={checkIsPast(eventClicked)}
            borderBottom={true}
            onClick={() => {
              setShowDropDown(false);
              handleSlotClick();
            }}
          >
            Add 1:1 Availability
          </StyledOption>
        </DropDown>
      )}
    </div>
  );
};

const mapStateToProps = ({ calendars, upcomingCreated: { loading } }) => ({
  calendars: calendars.calendarsAccounts,
  isloading: calendars.loading,
  calendarActiveView: calendars.currentView,
});

const actions = {
  addCalendar: calendarsActions.addCalendar,
  fetchCalendars: calendarsActions.fetchCalendars,
  calendarSignInCallback: calendarsActions.calendarSignInCallback,
  removeCalendar: calendarsActions.removeCalendar,
  rescheduleChosenSession: rescheduleSessionAction,
};

MasterCalendarPage.propTypes = {
  addCalendar: PropTypes.func.isRequired,
  fetchCalendars: PropTypes.func.isRequired,
  removeCalendar: PropTypes.func.isRequired,
  calendars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calendarActiveView: PropTypes.string.isRequired,
  isloading: PropTypes.bool.isRequired,
};
export default connect(mapStateToProps, actions)(MasterCalendarPage);
