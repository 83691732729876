import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import { TestimonialAvatar } from 'pages/ContributionView/ContributionReviews/components/TestimonialAvatar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { TemplateType } from 'helpers/constants/templateType';
import { lightOrDark } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { ContributionType, UserRoles } from 'helpers/constants';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const useStyles = makeStyles(theme => ({
  description: {
    '& .MuiFilledInput-multiline': {
      borderRadius: '4px',
      color: ({ color }) => color,
      border: 'none',
      '&:before': {
        content: 'none',
      },
      '&::placeholder': {
        color: ({ color }) => color,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ContributionReviewModal({ isOpen, handleSubmit, handleClose, item, avatarError, setAvatarError }) {
  const [file, setFile] = useState(null);
  const [isPublishDisabled, setIsPublishDisabled] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const styledProps = {
    cardBackgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);

  const type = contribution?.type;
  let colorToUse = determineColorToUse(contribution);
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  const btnColor = colorToUse?.PrimaryColorCode;
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const onSubmit = values => {
    if (file) {
      setIsPublishDisabled(true);
    }
    return handleSubmit({
      ...values,
      file,
      id: item?.id,
      addedToShowcase: item?.addedToShowcase,
      avatarUrl: item?.avatarUrl,
      isTemplate: item?.isTemplate,
    });
  };
  const handleUpload = ({ target: { files } }) => {
    setFile(files[0]);
    setAvatarUrl(URL.createObjectURL(files[0]));
    setAvatarError('');
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: themedCardBackgroundColor,
        },
      }}
      fullWidth
      open={isOpen}
    >
      <DialogTitle style={{ color: themedColor }}>
        <div style={{ color: themedColor }}>{`${item ? 'Update' : 'Create'} Testimonial`}</div>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            name: item?.name || '',
            role: item?.role || '',
            description: item?.description || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('This is a required field'),
            description: Yup.string().required('This is a required field'),
          })}
          onSubmit={onSubmit}
        >
          {({ handleChange, touched, errors, values }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs>
                  <TextField
                    name="name"
                    fullWidth
                    value={values?.name || item?.name}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                    label="Name"
                    InputLabelProps={{
                      style: { color: themedColor },
                    }}
                    inputProps={{
                      style: { color: themedColor, borderBottom: `1px solid ${themedColor}` },
                    }}
                    required
                    margin="normal"
                    variant="standard"
                  />
                  <TextField
                    name="role"
                    fullWidth
                    value={values?.role || item?.role}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: themedColor },
                    }}
                    inputProps={{
                      style: { color: themedColor, borderBottom: `1px solid ${themedColor}` },
                    }}
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                    label="Title"
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <TestimonialAvatar avatarUrl={avatarUrl || item?.avatarUrl} handleUpload={handleUpload} />
                    {/* {avatarError && <CommonErrorMessage message={avatarError} />} */}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item xs>
                <TextField
                  name="description"
                  multiline
                  value={values?.description || item?.description}
                  error={touched.description && errors.description}
                  margin="normal"
                  rows={4}
                  fullWidth
                  placeholder="Tell us how was your experience..."
                  inputProps={{
                    maxLength: 2000,
                  }}
                  required
                  variant="filled"
                  className={classes.description}
                  onChange={handleChange}
                />
              </Grid>
              <Typography style={{ fontSize: '0.75rem', color: '#000000de', textAlign: 'end' }}>
                {values?.description?.length}/2000
              </Typography>
              <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                <Button backgroundColor={btnColor} textColor={textColor} type="submit" autoWidth>
                  Publish
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

ContributionReviewModal.propTypes = {
  isOpen: PropTypes.bool,
  item: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};

ContributionReviewModal.defaultProps = {
  isOpen: false,
  item: {},
  handleSubmit: () => {},
  handleClose: () => {},
};

export default ContributionReviewModal;
