import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Modal from 'components/UI/Modal';
import * as userActions from 'actions/user';
import * as Yup from 'yup';
import { useAccount, useHttp } from 'hooks';
import {
  deleteCustomAvailabilityEntireSequence,
  getThemedColors,
  saveCustomAvailablilityTime,
  updateCustomAvailability,
  updateCustomAvailabilityEntireSequence,
} from 'services/contributions.service';
import { FieldArray, Formik } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  TextField,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import TimePickerField from 'components/FormUI/TimePickerField';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
// import { fetchTimeZones } from 'actions/timeZone';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
// import Tooltip from '@material-ui/core/Tooltip';
// import InfoIcon from '@material-ui/icons/Info';
// import { TOOLTIP } from '../../../constants';
// import { colors } from 'utils/styles';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import CustomSelect from 'components/FormUI/Select';
import moment from 'moment';
import { BsDash } from 'react-icons/bs';
import Button from 'components/FormUI/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from 'pages/CreateCampaign/Pages/SummaryPage/Components/Icons/SVG/EditIcon';
import DeleteIcon from './Icons/DeleteIcon';
import './AvailabilityRecurring.scss';
import DropdownIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/DropdownIcon';
import Loader from 'components/UI/Loader';
import RecurringCircleIcon from 'pages/CreateCampaign/Pages/SummaryPage/Components/Icons/SVG/RecurringCircleIcon';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { setCampaignNotification } from 'actions/campaign';
import Notifications from 'pages/Account/Notifications/Notifications';
import { isEmpty } from 'lodash';
import {
  fetchContributionTimes,
  fetchContributionTimesActions,
  fetchCustomContributionTimes,
  fetchCustomContributionTimesActions,
} from 'actions/contributions';
import { setPreSelectedMasterCalendarDate } from 'actions/masterCalendarSidebar.actions';

const StyledFormControlLabel = styled(FormControlLabel)`
  text-align: right;
`;
const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  .MuiSelect-icon {
    right: 8px;
  }
`;
const StyledBsDash = styled(BsDash)``;
const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 14,
    fontWeight: 900,
    fontFamily: 'Avenir',
    padding: '0 9px 0px 0px',
    letterSpacing: '1.25px',
  },
  root: {
    marginLeft: '-30px',
  },
  heading: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 350,
    lineHeight: '24.69px',
    textAlign: 'left',
    color: '#000000DE',
    paddingBottom: '5px',
  },
  allOccurrencesLink: {
    textDecoration: 'underline',
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    cursor: 'pointer',
    textAlign: 'left',
  },
  availableDays: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 350,
    lineHeight: '24.69px',
    textAlign: 'left',
    color: '#000000DE',
    paddingBottom: '10px',
  },
  cancelBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    fontSize: '14px',
    fontWeight: '900',
    borderRadius: '4px',
    color: '#000000',
    padding: '11px 24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doneBtn: {
    fontSize: '14px',
    fontWeight: '900',
    borderRadius: '4px',
    padding: '11px 30px',
  },
});

const StyledDateTimeDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: #215c73;
  color: #ffffff;
  padding: 5px 10px;
  gap: 6px;
  border-radius: 4px;
  max-width: 335px;
  justify-content: space-between;
`;
const StyledDateTime = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #ffffff;
`;
const RecuuringModalBodyText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #000000de;
  padding: 0px 0px 20px 0px;
`;
const StyledEditIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
`;

const StyledRecurringIcon = styled.div`
  padding-left: 5px;
`;

const StyledCloseIcon = styled.div`
  cursor: pointer;
`;
const OccurencesDetails = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #000000;
  padding: 10px 0px 4px 0px;
`;
const AllOccurrencesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.8px solid #dfe3e4;
  padding: 16px 4px;
`;
const StyledDateView = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000000;
`;
const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #215c73;
  margin-right: 3px;
`;
const OccurencesDateTime = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 16px;
  color: #000000de;
`;
const OccurencesActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const EditRecurringModalBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  border-top: 0.8px solid #e7e7e7;
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 10px 0px 10px 0px;
          flex-direction: column;
        `
      : css`
          padding: 15px 0px 0px 0px;
        `};
`;
const StyledOneOccrenceBtn = styled(Button)`
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 9px 20px;
          min-width: 22rem !important;
          border: 1px solid #c9b382;
          font-size: 14px;
        `
      : css`
          padding: 11px 30px;
        `};
`;
const StyledAllOccrenceBtn = styled(Button)`
  background: transparent;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  text-align: center;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.87);
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 9px 20px;
          min-width: 22rem !important;
        `
      : css`
          padding: 11px 30px;
        `};
  &:hover {
    background: transparent;
  }
`;

// const StyledTextField = withStyles({
//   root: {
//     '& .MuiInput-underline:hover': {
//       borderColor: 'transparent !important', // Your custom colo
//     },
//     '& .MuiInput-underline:focus': {
//       borderColor: 'transparent !important', // Your custom colo
//     },
//     '& .MuiOutlinedInput-root': {
//       borderColor: '1px solid rgba(0, 0, 0, 0.23) !important', // Your custom color
//       '&.Mui-focused fieldset': {
//         borderColor: 'transparent !important', // Your custom colo
//       },
//     },
//   },
// })(TextField);

const StyledTextField = styled(TextField)`
  border: 1px solid rgb(231, 231, 231) !important;
  ${({ error }) =>
    error &&
    `
 border: 1px solid red !important;
`}
  border-radius: 4px !important;
  font-family: Avenir !important;
  & .MuiInputBase-input {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 350 !important;
    outline: none !important;
    padding: 13px 5px !important;
  }
  && .MuiInput-underline:before {
    border-bottom: 1px solid transparent; /* Default color */
  }
  && .MuiInput-underline:after {
    border-bottom: 1px solid transparent;
  }
  && .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid transparent;
  }
`;

export const AvailabilityRecurring = memo(
  ({
    selectedDate = null,
    startTime = null,
    incrementNewSlots = () => {},
    deleteRecurring = () => {},
    oneToOneAvailability,
    setOneToOneAvailability,
    IsMasterCalendarModal = false,
    contributions = [],
    setIsExpansionDisabledAvailability = () => {},
    setallOccurencesModal = () => {},
    allOccurencesModal = false,
    masterCalendarAvailabilityModal = false,
  }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const campaignNotification = useSelector(state => state?.campaigns?.campaignNotification);
    const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
    const [showLoader, setShowLoader] = useState(false);
    const [overlappingAllowed, setOverlappingAllowed] = useState(false);
    const [recurrencePattern, setRecurrencePattern] = useState(null);
    const [recurringCustomId, setRecurringCustomId] = useState(null);
    const classes = useStyles();
    const { user } = useAccount();
    const { activeContribution, customContributionTimes, fetchSlotsCalendarLoader } = useSelector(
      state => state.contributions,
    );
    const { sessionDuration } = useSelector(state => state.oneToOneData);
    const datetimeRef = useRef(null);
    const datetimeRefSecond = useRef(null);
    const formRef = useRef(null);
    const editRecurringFormRef = useRef(null);
    const editSingleRecurringFormRef = useRef(null);
    const [showAllOccurrencesModal, setShowAllOccurrencesModal] = useState(false);
    const [editOccurrencesRow, setEditOccurrencesRow] = useState(null);
    const [editOccurrencesRowData, setEditOccurrencesRowData] = useState(null);
    const [editSelectedOccurrence, setEditSelectedOccurrence] = useState(false);
    const [availabilityInReviewToEdit, setAvailabilityInReviewToEdit] = useState(null);
    const [editEntireSeries, setEditEntireSeries] = useState(false);
    const [availabilityInReviewToDelete, setAvailabilityInReviewToDelete] = useState(null);

    useEffect(() => {
      if (oneToOneAvailability === null) {
        formRef.current.resetForm();
      }
    }, [customContributionTimes]);

    useEffect(() => {
      if (IsMasterCalendarModal && contributions.length === 1 && activeContribution != null) {
        dispatch(
          fetchCustomContributionTimes(
            contributions[0].id,
            activeContribution?.oneToOneSessionDataUi?.sessionDuration,
            activeContribution?.oneToOneSessionDataUi?.sessionDuration,
          ),
        );
      } else if (IsMasterCalendarModal && contributions.length > 1) {
        dispatch(fetchContributionTimesActions.success([]));
        dispatch(fetchCustomContributionTimesActions.success([]));
      }
    }, [contributions, activeContribution]);

    const getDayNumber = day => {
      const daysOfWeek = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };

      return daysOfWeek[day.toLowerCase()] ?? -1;
    };

    const getDaysFromNumbers = numbers => {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      return numbers.map(num => daysOfWeek[num] ?? 'Invalid');
    };
    const WeekValues = [
      { title: 'Monday', value: 'Monday' },
      { title: 'Tuesday', value: 'Tuesday' },
      { title: 'Wednesday', value: 'Wednesday' },
      { title: 'Thursday', value: 'Thursday' },
      { title: 'Friday', value: 'Friday' },
      { title: 'Saturday', value: 'Saturday' },
      { title: 'Sunday', value: 'Sunday' },
    ];

    const findCountOf = date => {
      const c = date / 7;
      if (c > 4) {
        return 5;
      } else if (c > 3 && (c < 4 || c === 4)) {
        return 4;
      } else if (c > 2 && (c < 3 || c === 3)) {
        return 3;
      } else if (c > 1 && (c < 2 || c === 2)) {
        return 2;
      } else {
        return 1;
      }
    };
    function isLastWeekOfMonth(year, month, day) {
      // Create a new date object with the selected date
      var selectedDate = new Date(year, month - 1, day);
      // Get the total number of days in the month
      var lastDay = new Date(year, month, 0).getDate();
      // Calculate the day of the week for the last day of the month
      var lastDayOfWeek = new Date(year, month - 1, lastDay).getDay();
      // Calculate the day of the week for the selected date
      var selectedDayOfWeek = selectedDate.getDay();
      // Determine if the selected date falls in the last week
      return lastDay - day <= 6 && selectedDayOfWeek >= lastDayOfWeek;
    }
    const todayDay = moment(oneToOneAvailability).format('dddd');
    const todayDate = moment(oneToOneAvailability).format('D');

    const handleClick = () => {
      if (datetimeRef.current) {
        datetimeRef.current.click();
      }
    };
    const handleClickSecond = () => {
      if (datetimeRefSecond.current) {
        datetimeRefSecond.current.click();
      }
    };

    useEffect(() => {
      if (selectedDate != null && startTime === null) {
        setTimeout(() => {
          handleDatePick(moment(selectedDate));
        }, [2000]);
      }
      if (selectedDate != null && startTime != null) {
        setTimeout(() => {
          handleDatePick(moment(selectedDate));
          handleStartTimePick(startTime);
        }, [2000]);
      }
    }, [selectedDate, formRef.current, startTime]);

    useEffect(() => {
      if (oneToOneAvailability === null) {
        setIsExpansionDisabledAvailability(false);
        formRef.current.setFieldValue(
          'startTime',
          moment.utc().set('hour', 9).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        );
        formRef.current.setFieldValue(
          'endTime',
          moment.utc().set('hour', 17).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        );
        formRef.current.setErrors({ endTime: null });
        formRef.current.setErrors({ startTime: null });
      }
    }, [oneToOneAvailability]);

    const handleDatePick = selectedMomentDate => {
      if (formRef.current) {
        const selectedDate = selectedMomentDate.format();
        formRef.current.setFieldValue('date', selectedDate);
        const selectedMoment = moment(selectedDate);

        const newDate = selectedMoment.add(1, 'year');
        formRef.current.setFieldValue('endDate', newDate);
      }
    };

    const handleStartTimePick = selectedMomentDate => {
      if (formRef.current) {
        const selectedTime = moment(selectedMomentDate);
        formRef.current.setFieldValue('startTime', selectedTime);
      }
    };
    const handleDatePickRecurring = selectedMomentDate => {
      const selectedDate = selectedMomentDate.format();
      formRef.current.setFieldValue('endDate', selectedDate);
    };

    const handleDatePickRecurringEdit = selectedMomentDate => {
      const selectedDate = selectedMomentDate.format();
      editRecurringFormRef.current.setFieldValue('endDate', selectedDate);
    };

    const saveHandler = () => {
      setShowLoader(true);
      const startTime = moment(formRef.current.values.startTime);
      const endTime = moment(formRef.current.values.endTime);
      const startTimeString = startTime.format('HH:mm:ss');
      const endTimeString = endTime.format('HH:mm:ss');

      if (startTimeString > endTimeString || startTimeString === endTimeString) {
        formRef.current.setErrors({ endTime: 'End time must be later than start time.' });
        setShowLoader(false);
      } else {
        // Reset the error if comparison is successful
        formRef.current.setErrors({});
        const currentDate = moment(formRef.current.values.date).format('YYYY-MM-DD');
        const currentTimeEnd = moment(`${currentDate} ${formRef.current.values.endTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const currentTimeStart = moment(`${currentDate} ${formRef.current.values.startTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const params = formRef.current.values.isResubmissionVal
          ? {
              overRideConflictingSlots: false,
              userId: user.id,
              makeCustomAvailabilityRecurring: false,
              endTime: currentTimeEnd,
              startTime: currentTimeStart,
              customDate: currentDate,
              initialRangeStartTime: currentTimeStart,
              initialRangeEndTime: currentTimeEnd,
              timeZone: formRef.current.values?.timezone,
              contributionIds: IsMasterCalendarModal
                ? masterCalendarArray.map(item => item?.id)
                : formRef.current.values?.contributions,
              makeCustomAvailabilityRecurring: formRef.current.values.isResubmissionVal,
              recurrenceSettings: {
                repeatInterval: formRef.current.values?.interval,
                repeatUnit:
                  formRef.current.values?.recurrencePattern === 'monthly'
                    ? 'Month'
                    : formRef.current.values?.recurrencePattern === 'daily'
                    ? 'Day'
                    : formRef.current.values?.recurrencePattern === 'weekly'
                    ? 'Week'
                    : 'Year',
                repeatDay:
                  formRef.current.values?.recurrencePattern === 'weekly'
                    ? formRef.current.values?.daysOfWeek
                    : getDayNumber(formRef.current.values?.dayOfWeek),
                endDate: formRef.current.values?.endDate,
                daysOfWeek: formRef.current.values?.daysOfWeek,
                isDayOfWeek: formRef.current.values?.isDayOfWeek,
                dayOfMonth: formRef.current.values?.dayOfMonth,
                weekOfMonth: formRef.current.values?.weekOfMonth,
                dayOfWeek: formRef.current.values?.dayOfWeek,
              },
              recurringSessions: {
                interval: formRef.current.values?.interval,
                recurrencePattern: formRef.current.values?.recurrencePattern,
                repeatOn: formRef.current.values?.repeatOn,
                repeatOnData: formRef.current.values?.repeatOnData,
                endson: formRef.current.values?.endson,
                endDate: formRef.current.values?.endDate,
                occurrences: formRef.current.values?.occurrences,
                occurrence: formRef.current.values?.occurrence,
                daysOfWeek: formRef.current.values?.daysOfWeek,
                isDayOfWeek: formRef.current.values?.isDayOfWeek,
                dayOfMonth: formRef.current.values?.dayOfMonth,
                weekOfMonth: formRef.current.values?.weekOfMonth,
                dayOfWeek: formRef.current.values?.dayOfWeek,
              },
            }
          : {
              overRideConflictingSlots: false,
              userId: user.id,
              makeCustomAvailabilityRecurring: false,
              endTime: currentTimeEnd,
              startTime: currentTimeStart,
              customDate: currentDate,
              timeZone: formRef.current.values?.timezone,
              contributionIds: IsMasterCalendarModal
                ? masterCalendarArray.map(item => item?.id)
                : formRef.current.values?.contributions,
            };
        setTimeout(() => {
          setOneToOneAvailability(null);
        }, 500);
        saveCustomAvailablilityTime(params)
          .then(res => {
            setOneToOneAvailability(null);
            setIsExpansionDisabledAvailability(false);
            if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
              dispatch(fetchContributionTimes(masterCalendarArray[0].id, true));
              dispatch(
                fetchCustomContributionTimes(
                  masterCalendarArray[0].id || activeContribution.id,
                  activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                  activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                ),
              );
            }
            setOneToOneAvailability(null);
            setShowLoader(false);
            if (res.includes('Overlapping slots found')) {
              setOverlappingAllowed(true);
            }
            if (res.includes('Overlapping slots found') === false) {
              console.log('enter');
              incrementNewSlots();
              setOneToOneAvailability(null);
              dispatch(
                setCampaignNotification({
                  message: 'test',
                  heading: 'test',
                }),
              );
            }
          })
          .catch(err => {
            setOneToOneAvailability(null);
            setIsExpansionDisabledAvailability(false);
            setShowLoader(false);
            console.log('err', err);
            setOneToOneAvailability(null);
          });
      }
    };

    const handleEditsubmit = () => {
      setShowLoader(true);
      const startTime = moment(editRecurringFormRef.current.values.startTime);
      const endTime = moment(editRecurringFormRef.current.values.endTime);
      const startTimeString = startTime.format('HH:mm:ss');
      const endTimeString = endTime.format('HH:mm:ss');
      if (editEntireSeries) {
        if (
          startTimeString > endTimeString ||
          startTimeString === endTimeString ||
          (editRecurringFormRef.current.values?.recurrencePattern === 'weekly' &&
            editRecurringFormRef.current.values.daysOfWeek.length === 0) ||
          (editRecurringFormRef.current.values?.recurrencePattern === 'monthly' &&
            editRecurringFormRef.current.values.repeatOn.length === 0)
        ) {
          if (
            (editRecurringFormRef.current.values?.recurrencePattern === 'weekly' &&
              editRecurringFormRef.current.values.daysOfWeek.length === 0) ||
            (editRecurringFormRef.current.values?.recurrencePattern === 'monthly' &&
              editRecurringFormRef.current.values.repeatOn.length === 0)
          ) {
            setShowLoader(false);
          } else {
            editRecurringFormRef.current.setErrors({ endTime: 'End time must be later than start time.' });
            setShowLoader(false);
          }
        } else {
          const date = moment(editRecurringFormRef.current.values.date).format('YYYY-MM-DD');
          const endDay = moment(`${date} ${editRecurringFormRef.current.values.endTime.format('HH:mm:00')}`).format(
            'YYYY-MM-DDTHH:mm:00',
          );
          const startDay = moment(`${date} ${editRecurringFormRef.current.values.startTime.format('HH:mm:00')}`).format(
            'YYYY-MM-DDTHH:mm:00',
          );
          const oneToOneTimeRange = {
            endTime: endDay,
            startTime: startDay,
          };
          const dataToUpdate = {
            contributionIds:
              IsMasterCalendarModal && editRecurringFormRef.current.values?.contributions.length > 1
                ? editRecurringFormRef.current.values?.contributions
                : editRecurringFormRef.current.values?.contributions,
            dateTime: availabilityInReviewToEdit.startTime,
            initialRangeStartTime: startDay,
            initialRangeEndTime: endDay,
            sessionDuration: sessionDuration || 60,
            ...oneToOneTimeRange,
            customDate: availabilityInReviewToEdit.startTime,
            timezone: user.timeZoneId,
            oldCustomAvailability: {
              startTime: availabilityInReviewToEdit.startTime,
              endTime: availabilityInReviewToEdit.endTime,
            },
            customAvailabilityId: availabilityInReviewToEdit.customAvailabilityId,
            makeCustomAvailabilityRecurring: editRecurringFormRef.current.values.isResubmissionVal,
            recurringSessions: {
              interval: editRecurringFormRef.current.values?.interval,
              recurrencePattern: editRecurringFormRef.current.values?.recurrencePattern,
              repeatOn: editRecurringFormRef.current.values?.repeatOn,
              repeatOnData: editRecurringFormRef.current.values?.repeatOnData,
              endson: editRecurringFormRef.current.values?.endson,
              endDate: editRecurringFormRef.current.values?.endDate,
              occurrences: editRecurringFormRef.current.values?.occurrences,
              occurrence: editRecurringFormRef.current.values?.occurrence,
              daysOfWeek: editRecurringFormRef.current.values?.daysOfWeek,
              isDayOfWeek: editRecurringFormRef.current.values?.isDayOfWeek,
              dayOfMonth: editRecurringFormRef.current.values?.dayOfMonth,
              weekOfMonth: editRecurringFormRef.current.values?.weekOfMonth,
              dayOfWeek: moment(startDay).format('dddd'),
            },
          };

          updateCustomAvailabilityEntireSequence(dataToUpdate)
            .then(res => {
              if (res.includes('Overlapping slots found')) {
                setOverlappingAllowed(true);
              } else {
                dispatch(
                  setCampaignNotification({
                    message: 'test',
                    heading: 'test',
                  }),
                );
                if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
                  dispatch(fetchContributionTimes(masterCalendarArray[0].id, true));
                  dispatch(
                    fetchCustomContributionTimes(
                      masterCalendarArray[0].id || activeContribution.id,
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                    ),
                  );
                }
              }
              setShowLoader(false);
              setEditEntireSeries(false);
              setEditSelectedOccurrence(false);
              setOneToOneAvailability(null);
              incrementNewSlots();
              console.log('res of update API-->', res);
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
              setEditEntireSeries(false);
              setEditSelectedOccurrence(false);
            })
            .catch(err => {
              setShowLoader(false);
              console.log('err', err);
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
              setEditEntireSeries(false);
              setEditSelectedOccurrence(false);
            });
        }
      } else {
        if (startTimeString > endTimeString || startTimeString === endTimeString) {
          editRecurringFormRef.current.setErrors({ endTime: 'End time must be later than start time.' });
          setShowLoader(false);
        } else {
          editRecurringFormRef.current.setErrors({});

          const date = moment(editRecurringFormRef.current.values.date).format('YYYY-MM-DD');
          const endDay = moment(`${date} ${editRecurringFormRef.current.values.endTime.format('HH:mm:00')}`).format(
            'YYYY-MM-DDTHH:mm:00',
          );
          const startDay = moment(`${date} ${editRecurringFormRef.current.values.startTime.format('HH:mm:00')}`).format(
            'YYYY-MM-DDTHH:mm:00',
          );
          const oneToOneTimeRange = {
            endTime: endDay,
            startTime: startDay,
          };
          const dataToUpdate = {
            contributionIds: IsMasterCalendarModal
              ? masterCalendarArray.map(item => item.id).join(', ')
              : editRecurringFormRef.current.values?.contributions[0],
            dateTime: availabilityInReviewToEdit.startTime,
            sessionDuration: sessionDuration || 60,
            newTimeRange: oneToOneTimeRange,
            isDelete: false,
            customAvailabilityId: availabilityInReviewToEdit.customAvailabilityId,
          };
          console.log('updatedData -->', dataToUpdate, sessionDuration);
          updateCustomAvailability(dataToUpdate)
            .then(res => {
              if (res.includes('Overlapping slots found')) {
                setOverlappingAllowed(true);
              } else {
                dispatch(
                  setCampaignNotification({
                    message: 'test',
                    heading: 'test',
                  }),
                );
                if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
                  dispatch(fetchContributionTimes(masterCalendarArray[0].id, true));
                  dispatch(
                    fetchCustomContributionTimes(
                      masterCalendarArray[0].id || activeContribution.id,
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                    ),
                  );
                }
              }
              setShowLoader(false);
              setEditEntireSeries(false);
              setEditSelectedOccurrence(false);
              setOneToOneAvailability(null);
              incrementNewSlots();
              console.log('res of update API-->', res);
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
            })
            .catch(err => {
              setShowLoader(false);
              console.log('err', err);
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
            });
        }
      }
    };

    const handleDeleteAvailiability = item => {
      setShowLoader(true);
      if (showAllOccurrencesModal === true) {
        setallOccurencesModal(true);
      } else {
        setallOccurencesModal(false);
      }

      const dataToDelete = {
        contributionIds: IsMasterCalendarModal
          ? masterCalendarArray.map(item => item.id).join(', ')
          : activeContribution?.id,
        dateTime: item?.startTime,
        sessionDuration: sessionDuration || 60,
        newTimeRange: null,
        isDelete: true,
        customAvailabilityId: item.customAvailabilityId,
      };
      updateCustomAvailability(dataToDelete)
        .then(res => {
          dispatch(
            setCampaignNotification({
              message: 'DELETE',
              heading: 'DELETE',
              isModal: item?.isModal,
            }),
          );
          if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
            dispatch(fetchContributionTimes(masterCalendarArray[0].id, true));
            dispatch(
              fetchCustomContributionTimes(
                masterCalendarArray[0].id || activeContribution.id,
                activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                activeContribution?.oneToOneSessionDataUi?.sessionDuration,
              ),
            );
          }
          setShowLoader(false);
          setAvailabilityInReviewToDelete(null);
          incrementNewSlots();
          setRecurrencePattern(null);
          setAvailabilityInReviewToEdit(null);
          setEditEntireSeries(false);
          setTimeout(() => {
            setallOccurencesModal(false);
          }, 5000);
          // Call the getSlots API and render the slots again the deleted availability row should also be removed
        })
        .catch(err => {
          console.log('err', err);
          setShowLoader(false);
          setRecurrencePattern(null);
          setAvailabilityInReviewToEdit(null);
          setEditEntireSeries(false);
          setallOccurencesModal(false);
        });
    };

    const uniqueByRecurrence = (array = [], key) => {
      if (array && array?.length > 0) {
        const seen = new Set();
        return array?.filter(item => {
          if (item.customAvailabilityId != null && item.recurrenceSettings != null) {
            const value = item[key];
            if (seen.has(value)) {
              return false;
            }
            seen.add(value);
            return true;
          }
        });
      } else {
        return [];
      }
    };

    const uniqueData = uniqueByRecurrence(customContributionTimes, 'customAvailabilityId');
    console.log('oneToOneAvailability', oneToOneAvailability);
    return (
      <>
        {(showLoader || fetchSlotsCalendarLoader) && <Loader />}

        <Formik
          innerRef={formRef}
          initialValues={{
            contributions: IsMasterCalendarModal
              ? contributions.map(item => item.id).join(', ')
              : [activeContribution?.id],
            date: null,
            startTime: moment.utc().set('hour', 9).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
            endTime: moment.utc().set('hour', 17).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
            timezone: user.timeZoneId,
            interval: 1,
            recurrencePattern: 'daily',
            repeatOn: [],
            repeatOnData: [],
            endson: true,
            occurrences: null,
            occurrence: false,
            daysOfWeek: [],
            isDayOfWeek: false,
            dayOfMonth: 1,
            weekOfMonth: 1,
            dayOfWeek: 'Sunday',
            isResubmissionVal: false,
            endDate: moment().add(1, 'year'),
          }}
          validationSchema={Yup.object().shape({
            interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
            recurrencePattern: Yup.string().required('This is a required field'),
            date: Yup.string().required('This is a required field').nullable(),
            startTime: Yup.string().required('This is a required field').nullable(),
            endTime: Yup.string().required('This is a required field').nullable(),
            daysOfWeek: Yup.array().when('recurrencePattern', {
              is: v => v === 'weekly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            repeatOn: Yup.array().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            dayOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            dayOfWeek: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            weekOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            // endDate: Yup.date().when('endson', {
            //   is: true,
            //   then: Yup.date().required('This is a required field'),
            //   // otherwise: Yup.date().nullable(),
            // }),
            occurrences: Yup.number().when('occurrence', {
              is: true,
              then: Yup.number()
                .integer()
                .required('This is a required field')
                .min(1, 'it must be 1 or more')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          })}
          onSubmit={saveHandler}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            const changeRepeatOnValues = e => {
              if (e?.target?.value === 'daily') {
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'weekly') {
                setFieldValue('repeatOnData', WeekValues);
                setFieldValue('daysOfWeek', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'monthly') {
                const todayDate = moment(moment(oneToOneAvailability)).format('D');
                const count = findCountOf(todayDate);
                var year = new Date(moment()).getFullYear();
                var month = new Date(moment()).getMonth();
                var day = todayDate;
                let lastWeek;
                if (isLastWeekOfMonth(year, month, day)) {
                  lastWeek = true;
                  console.log('Selected date is in the last week of the month.');
                } else {
                  lastWeek = false;
                  console.log('Selected date is not in the last week of the month.');
                }
                setFieldValue('repeatOnData', [
                  { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                  {
                    title: `Monthly on ${
                      count === 1
                        ? '1st'
                        : count === 2
                        ? '2nd'
                        : count === 3
                        ? '3rd'
                        : count === 4 && lastWeek
                        ? 'Last'
                        : count === 5
                        ? 'Last'
                        : 'Last'
                    } ${todayDay}`,
                    value: `Monthly on ${count} ${todayDay}`,
                  },
                ]);
                setFieldValue('repeatOn', []);
                setFieldValue('dayOfWeek', todayDay);
                setFieldValue('weekOfMonth', count);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else {
                setFieldValue('repeatOnData', null);
                setFieldValue('repeatOn', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              }
            };
            console.log('VALUES.ENDDATE----->', values.endDate);
            return (
              <>
                {oneToOneAvailability !== null && (
                  <div style={{ paddingBottom: '20px' }}>
                    <Grid style={{ maxWidth: '80%' }} lg={12}>
                      <span className={classes.heading}>Select a time range</span>
                    </Grid>

                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '10px',
                        // height: '40px',
                        alignItems: 'baseline',
                      }}
                      lg={12}
                    >
                      <Grid lg={IsMasterCalendarModal ? 6 : 4}>
                        <TimePickerField
                          onChange={value => setFieldValue('startTime', moment(value))}
                          showSecond={false}
                          value={values.startTime}
                          format="h:mm a"
                          use12Hours
                          minuteStep={15}
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #e7e7e7',
                            fontWeight: 350,
                            fontSize: '16px',
                            borderRadius: '4px',
                            alignItems: 'center',
                            padding: ' 21px 5px',
                          }}
                          className="custom-time-picker-nounderline"
                        />
                        {formRef.current != null && touched.startTime && errors?.startTime && (
                          <CommonErrorMessage
                            className="default-font-family"
                            message={errors?.startTime}
                            align="left"
                          />
                        )}
                      </Grid>
                      <div
                        style={{
                          padding: '0px 5px',
                        }}
                      >
                        <StyledBsDash />
                      </div>
                      <Grid lg={IsMasterCalendarModal ? 6 : 4}>
                        <TimePickerField
                          onChange={value => setFieldValue('endTime', moment(value))}
                          showSecond={false}
                          value={values.endTime}
                          format="h:mm a"
                          use12Hours
                          minuteStep={15}
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: 350,
                            fontSize: '16px',
                            border: '1px solid #e7e7e7',
                            borderRadius: '4px',
                            alignItems: 'center',
                            padding: ' 21px 5px',
                          }}
                          className="custom-time-picker-nounderline"
                        />
                        {formRef.current != null && errors?.endTime && touched.endTime && (
                          <CommonErrorMessage className="default-font-family" message={errors?.endTime} align="left" />
                        )}
                      </Grid>
                    </Grid>

                    <Grid style={{ marginTop: '10px', marginLeft: '20px' }} lg={12}>
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxLabel,
                          root: classes.root,
                        }}
                        checked={values.isResubmissionVal}
                        onChange={() => {
                          setFieldValue('isResubmissionVal', !values.isResubmissionVal);
                          setFieldValue('occurrences', null);
                          const todayDay = moment(moment()).format('dddd');
                          const todayDate = moment(moment()).format('D');
                          const count = findCountOf(todayDate);
                          var year = new Date(moment()).getFullYear();
                          var month = new Date(moment()).getMonth() + 1;
                          var day = todayDate;
                          let lastWeek;
                          if (isLastWeekOfMonth(year, month, day)) {
                            lastWeek = true;
                            console.log('Selected date is in the last week of the month.');
                          } else {
                            lastWeek = false;
                            console.log('Selected date is not in the last week of the month.');
                          }
                          if (values.recurrencePattern === 'monthly') {
                            setFieldValue('repeatOnData', [
                              { name: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                              {
                                name: `Monthly on ${
                                  count === 1
                                    ? '1st'
                                    : count === 2
                                    ? '2nd'
                                    : count === 3
                                    ? '3rd'
                                    : count === 4 && lastWeek
                                    ? 'Last'
                                    : count === 5
                                    ? 'Last'
                                    : '4th'
                                } ${todayDay}`,
                                value: `Monthly on ${count} ${todayDay}`,
                              },
                            ]);
                          } else {
                            setFieldValue('repeatOnData', WeekValues);
                          }
                        }}
                        control={<Checkbox color="primary" />}
                        label="Make Availability Recurring"
                        name="isResubmissionVal"
                      />
                    </Grid>

                    {values.isResubmissionVal && (
                      <>
                        <Grid
                          style={{
                            flexDirection: IsMasterCalendarModal || mobileView ? 'column' : 'row',
                            display: 'flex',
                            gap: '16px',
                          }}
                          lg={12}
                        >
                          <Grid lg={IsMasterCalendarModal ? 12 : 4}>
                            <Grid className={classes.heading}>Repeat Every</Grid>
                            <Grid style={{ display: 'flex', gap: '8px' }} lg={12}>
                              <Grid lg={IsMasterCalendarModal ? 6 : 4} sm={12} xs={12}>
                                <StyledTextField
                                  fullWidth
                                  name="interval"
                                  value={values?.interval}
                                  onChange={e => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value.toString() === '') {
                                      setFieldValue('interval', null);
                                    } else if (re.test(e?.target?.value)) {
                                      setFieldValue('interval', parseInt(e?.target?.value));
                                    }
                                  }}
                                  recurringField={true}
                                  error={errors && errors?.interval}
                                />
                              </Grid>
                              <Grid lg={IsMasterCalendarModal ? 6 : 8} sm={12} xs={12}>
                                <StyledCustomSelect
                                  showHelperText={false}
                                  style={{ fontFamily: 'Brandon Text' }}
                                  variant="outlined"
                                  name="recurrencePattern"
                                  defaultValue={values?.recurrencePattern}
                                  fullWidth
                                  // input={<OutlinedInput margin="dense" />}
                                  onChange={changeRepeatOnValues}
                                  className="custom-input-style"
                                  items={[
                                    { value: 'daily', title: values?.interval > 1 ? 'Days' : 'Day' },
                                    {
                                      value: 'weekly',
                                      title: values?.interval > 1 ? 'Weeks' : 'Week',
                                    },
                                    {
                                      value: 'monthly',
                                      title: values?.interval > 1 ? 'Months' : 'Month',
                                    },
                                    {
                                      value: 'yearly',
                                      title: values?.interval > 1 ? 'Years' : 'Year',
                                    },
                                  ]}
                                  roundedBorder={true}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            style={{
                              display: 'flex',
                              gap: IsMasterCalendarModal ? '8px' : '16px',
                              flexDirection: mobileView ? 'column' : 'row',
                            }}
                            lg={IsMasterCalendarModal ? 12 : 8}
                          >
                            {(values?.recurrencePattern === 'weekly' || values?.recurrencePattern === 'monthly') && (
                              <Grid lg={6} sm={12} xs={12}>
                                <Grid className={classes.heading}>Repeat On</Grid>
                                <Grid container xs={12} sm={12}>
                                  {values.recurrencePattern === 'weekly' ? (
                                    <StyledCustomSelect
                                      showHelperText={false}
                                      underLineColor={'transparent'}
                                      name="daysOfWeek"
                                      multiple
                                      className="custom-input-style"
                                      items={WeekValues}
                                      fullWidth
                                      style={{ marginTop: '0px' }}
                                      roundedBorder={true}
                                    />
                                  ) : (
                                    <>
                                      <StyledCustomSelect
                                        showHelperText={false}
                                        underLineColor={'transparent'}
                                        name="repeatOn"
                                        style={{ marginTop: '0px' }}
                                        className="custom-input-style"
                                        fullWidth
                                        value={values?.repeatOn}
                                        roundedBorder={true}
                                        onChange={e => {
                                          if (e?.target?.value === `Monthly on day ${todayDate}`) {
                                            setFieldValue('isDayOfWeek', false);
                                            setFieldValue('dayOfMonth', parseInt(todayDate));
                                          } else {
                                            const count = findCountOf(todayDate);
                                            setFieldValue('isDayOfWeek', true);
                                            setFieldValue('dayOfWeek', todayDay);
                                            setFieldValue('weekOfMonth', parseInt(count));
                                          }
                                          setFieldValue('repeatOn', [e?.target?.value]);
                                        }}
                                        items={values?.repeatOnData}
                                      />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            <Grid lg={6}>
                              <div style={{ display: 'flex', flexDirection: 'column' }} className="default-font-family">
                                <div className={classes.heading}>End Date</div>
                                <div>
                                  <div
                                    style={{
                                      position: 'relative',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <DatePicker
                                      inputRef={datetimeRef}
                                      fullWidth
                                      disablePast
                                      onChange={momentDate => handleDatePickRecurring(momentDate)}
                                      value={values.endDate}
                                      format={'MMM DD, YYYY'}
                                      minDate={moment(values.date).add(1, 'day')}
                                      TextFieldComponent={props => <StyledTextField {...props} />}
                                    />
                                    <div style={{ cursor: 'pointer' }}>
                                      <DropdownIcon width="12" height="7" color="#00000066" availability={true} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <div style={{ display: 'flex', gap: '10px', paddingTop: '12px' }}>
                      <Button
                        autoWidth
                        className={classes.cancelBtn}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          deleteRecurring();
                          setIsExpansionDisabledAvailability(false);
                          dispatch(setPreSelectedMasterCalendarDate(null));
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        autoWidth
                        className={classes.doneBtn}
                        disabled={
                          IsMasterCalendarModal
                            ? masterCalendarArray?.length === 0 || masterCalendarArray[0] === null
                            : false
                        }
                        onClick={() => {
                          dispatch(setPreSelectedMasterCalendarDate(null));
                          formRef.current.handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
        {customContributionTimes?.length > 0 && (
          <>
            {customContributionTimes.length > 0 && <div className={classes.availableDays}>One-time / Recurring</div>}
            {customContributionTimes
              .filter(item => item.recurrencePattern === null || item.recurrenceSettings === null)
              ?.map((item, index) => {
                return (
                  <div style={{ paddingBottom: '20px' }} key={index}>
                    <StyledDateTimeDiv>
                      <StyledDateTime>
                        {' '}
                        {`${moment(item.startTime).format('M/D/YYYY, h:mm a')} - ${moment(item.endTime).format(
                          'h:mm a',
                        )}`}
                      </StyledDateTime>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <StyledEditIcon
                          onClick={() => {
                            dispatch(setCampaignNotification({}));
                            if (item.recurrencePattern > 1) {
                              setRecurrencePattern(item.recurrencePattern);
                            }
                            if (
                              item.recurrencePattern === null ||
                              item.recurrencePattern == 1 ||
                              item.recurrenceSettings === null
                            ) {
                              setAvailabilityInReviewToEdit(item);
                              setEditSelectedOccurrence(true);
                            } else {
                              setAvailabilityInReviewToEdit(item);
                            }
                          }}
                        >
                          <EditIcon color="white" width="14px" height="14px" />
                        </StyledEditIcon>
                        <StyledCloseIcon
                          onClick={() => {
                            dispatch(setCampaignNotification({}));
                            if (item.recurrencePattern > 1) {
                              setRecurrencePattern(item.recurrencePattern);
                            }
                            setAvailabilityInReviewToDelete(item);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </StyledCloseIcon>
                      </div>
                    </StyledDateTimeDiv>
                  </div>
                );
              })}
            {uniqueData?.map((item, index) => {
              console.log(
                '${getDaysFromNumbers(item?.recurrenceSettings?.daysOfWeek)}',
                getDaysFromNumbers(item?.recurrenceSettings?.daysOfWeek),
              );
              return (
                <div style={{ paddingBottom: '20px' }} key={index}>
                  <StyledDateTimeDiv>
                    <StyledDateTime>
                      {' '}
                      {`${moment(item.startTime).format('M/D/YYYY, h:mm a')} - ${moment(item.endTime).format(
                        'h:mm a',
                      )}`}
                    </StyledDateTime>
                    {item.recurrencePattern > 1 && (
                      <StyledRecurringIcon>
                        <RecurringCircleIcon />
                      </StyledRecurringIcon>
                    )}
                    <StyledEditIcon
                      onClick={() => {
                        dispatch(setCampaignNotification({}));
                        setRecurrencePattern(item.recurrencePattern);
                        setRecurringCustomId(item.customAvailabilityId);
                        if (item.recurrencePattern === null || item.recurrencePattern == 1) {
                          setEditSelectedOccurrence(true);
                          setAvailabilityInReviewToEdit(item);
                        } else {
                          setAvailabilityInReviewToEdit(item);
                        }
                      }}
                    >
                      <EditIcon color="white" width="14px" height="14px" />
                    </StyledEditIcon>
                    <StyledCloseIcon
                      onClick={() => {
                        dispatch(setCampaignNotification({}));
                        setRecurringCustomId(item.customAvailabilityId);
                        setRecurrencePattern(item.recurrencePattern);
                        setAvailabilityInReviewToDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </StyledCloseIcon>
                  </StyledDateTimeDiv>
                  {uniqueData.length > 0 && item.recurrencePattern > 1 && (
                    <>
                      <OccurencesDetails>{`Every ${item?.recurrenceSettings?.interval} ${
                        item?.recurrenceSettings?.recurrencePattern === 'weekly'
                          ? item?.recurrenceSettings?.interval > 1
                            ? 'Weeks on'
                            : 'Week on'
                          : item?.recurrenceSettings?.recurrencePattern === 'monthly'
                          ? item?.recurrenceSettings?.interval > 1
                            ? 'Months on'
                            : 'Month on'
                          : item?.recurrenceSettings?.recurrencePattern === 'daily'
                          ? item?.recurrenceSettings?.interval > 1
                            ? 'Days on'
                            : 'Day on'
                          : item?.recurrenceSettings?.interval > 1
                          ? 'Years on'
                          : 'Year on'
                      } ${
                        item?.recurrenceSettings?.recurrencePattern === 'weekly'
                          ? ` ${getDaysFromNumbers(item?.recurrenceSettings?.daysOfWeek)}`.replace(/,/g, ', ')
                          : item?.recurrenceSettings?.recurrencePattern === 'monthly'
                          ? ` ${item?.recurrenceSettings?.repeatOnData
                              .find(val => val.value === item?.recurrenceSettings?.repeatOn[0])
                              ?.title.replace('Monthly on ', '')}`
                          : ''
                      } until ${moment(item?.recurrenceSettings?.endDate).format('MMM DD, YYYY,')} ${
                        item?.recurrencePattern
                      } occurrence(s).`}</OccurencesDetails>
                      <div
                        className={classes.allOccurrencesLink}
                        onClick={() => {
                          setShowAllOccurrencesModal(true);
                          dispatch(setCampaignNotification({}));
                          setRecurrencePattern(item.recurrencePattern);
                          setRecurringCustomId(item.customAvailabilityId);
                        }}
                      >
                        Show all occurrences
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}

        {availabilityInReviewToEdit &&
          availabilityInReviewToEdit.recurrencePattern != null &&
          availabilityInReviewToEdit.recurrencePattern != 1 && (
            <Modal
              title="Edit Recurring Availability"
              isOpen={availabilityInReviewToEdit}
              isCreatingContribution={true}
              brandingColor={false}
              disableOverlayClick
              onCancel={() => {
                setRecurrencePattern(null);
                setAvailabilityInReviewToEdit(null);
                setEditEntireSeries(false);
              }}
              disableFooter={true}
              minHeight={'170px'}
            >
              <RecuuringModalBodyText>
                Please confirm if you’d like to edit the entire series or single occurrence.
              </RecuuringModalBodyText>
              <EditRecurringModalBtns mobileView={mobileView}>
                {recurrencePattern != null && availabilityInReviewToEdit.recurrenceSettings != null && (
                  <StyledAllOccrenceBtn
                    mobileView={mobileView}
                    onClick={() => {
                      setEditEntireSeries(true);
                      setEditSelectedOccurrence(true);
                      dispatch(setCampaignNotification({}));
                    }}
                  >
                    Edit Entire Series
                  </StyledAllOccrenceBtn>
                )}
                <StyledOneOccrenceBtn
                  mobileView={mobileView}
                  autoWidth
                  onClick={() => {
                    setEditSelectedOccurrence(true);
                    dispatch(setCampaignNotification({}));
                  }}
                >
                  Edit This Occurrence
                </StyledOneOccrenceBtn>
              </EditRecurringModalBtns>
            </Modal>
          )}

        {availabilityInReviewToDelete && (
          <Modal
            title={
              recurrencePattern != null &&
              availabilityInReviewToDelete.recurrenceSettings != null &&
              recurrencePattern > 1
                ? 'Delete Recurring Availability'
                : 'Delete Availability'
            }
            isCreatingContribution={true}
            brandingColor={false}
            disableOverlayClick
            isOpen={availabilityInReviewToDelete}
            onCancel={() => {
              setRecurrencePattern(null);
              setAvailabilityInReviewToDelete(false);
            }}
            disableFooter={true}
            minHeight={'170px'}
          >
            {recurrencePattern != null &&
            availabilityInReviewToDelete.recurrenceSettings != null &&
            recurrencePattern > 1 ? (
              <RecuuringModalBodyText>
                Please confirm if you’d like to delete the entire series or single occurrence.
              </RecuuringModalBodyText>
            ) : (
              <RecuuringModalBodyText>Are you sure you want to delete this availability?</RecuuringModalBodyText>
            )}
            <EditRecurringModalBtns mobileView={mobileView}>
              {recurrencePattern != null &&
                availabilityInReviewToDelete.recurrenceSettings != null &&
                recurrencePattern > 1 && (
                  <StyledAllOccrenceBtn
                    mobileView={mobileView}
                    onClick={() => {
                      setShowLoader(true);
                      deleteCustomAvailabilityEntireSequence({
                        customAvailabilityId: availabilityInReviewToDelete.customAvailabilityId,
                        contributionId: IsMasterCalendarModal
                          ? masterCalendarArray.map(item => item.id).join(', ')
                          : activeContribution?.id,
                        timeRange: {
                          startTime: availabilityInReviewToDelete.startTime,
                          endTime: availabilityInReviewToDelete.endTime,
                        },
                      })
                        .then(() => {
                          dispatch(
                            setCampaignNotification({
                              message: 'DELETE',
                              heading: 'DELETE',
                            }),
                          );
                          if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
                            dispatch(
                              fetchCustomContributionTimes(
                                masterCalendarArray[0].id || activeContribution.id,
                                activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                                activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                              ),
                            );
                          }
                          setShowLoader(false);
                          setAvailabilityInReviewToDelete(false);
                          incrementNewSlots();
                        })
                        .catch(() => {
                          setShowLoader(false);
                        });
                    }}
                    autoWidth
                  >
                    Delete Entire Series
                  </StyledAllOccrenceBtn>
                )}
              {recurrencePattern != null &&
                availabilityInReviewToDelete.recurrenceSettings != null &&
                recurrencePattern > 1 && (
                  <StyledOneOccrenceBtn
                    mobileView={mobileView}
                    autoWidth
                    onClick={() => handleDeleteAvailiability(availabilityInReviewToDelete)}
                  >
                    Delete This Occurrence
                  </StyledOneOccrenceBtn>
                )}
              {(recurrencePattern != null &&
                availabilityInReviewToDelete.recurrenceSettings != null &&
                recurrencePattern > 1) === false && (
                <StyledOneOccrenceBtn
                  mobileView={mobileView}
                  autoWidth
                  onClick={() => {
                    setRecurrencePattern(null);
                    setAvailabilityInReviewToDelete(false);
                  }}
                >
                  No
                </StyledOneOccrenceBtn>
              )}
              {(recurrencePattern != null &&
                availabilityInReviewToDelete.recurrenceSettings != null &&
                recurrencePattern > 1) === false && (
                <StyledOneOccrenceBtn
                  mobileView={mobileView}
                  autoWidth
                  onClick={() => handleDeleteAvailiability(availabilityInReviewToDelete)}
                >
                  Yes
                </StyledOneOccrenceBtn>
              )}
            </EditRecurringModalBtns>
          </Modal>
        )}

        {editSelectedOccurrence && (
          <Modal
            title={'Edit Availability'}
            isOpen={editSelectedOccurrence}
            isCreatingContribution={true}
            brandingColor={false}
            disableOverlayClick
            onCancel={() => {
              setEditEntireSeries(false);
              setEditSelectedOccurrence(false);
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
              dispatch(setCampaignNotification({}));
            }}
            minWidth={mobileView ? '300px' : '600px'}
            max-width={mobileView ? '300px' : '600px'}
            disableFooter={true}
          >
            <Formik
              innerRef={editRecurringFormRef}
              initialValues={{
                contributions: activeContribution?.id,
                startTime: moment(
                  editEntireSeries
                    ? availabilityInReviewToEdit?.originalSelectedRange?.startTime
                    : availabilityInReviewToEdit?.startTime,
                ),
                endTime: moment(
                  editEntireSeries
                    ? availabilityInReviewToEdit?.originalSelectedRange?.endTime
                    : availabilityInReviewToEdit?.endTime,
                ),
                date: moment(availabilityInReviewToEdit?.startTime || new Date()).format('MMMM DD,YYYY'),
                contributions: [activeContribution?.id],
                interval: availabilityInReviewToEdit?.recurrenceSettings?.interval || 0,
                recurrencePattern: availabilityInReviewToEdit?.recurrenceSettings?.recurrencePattern,
                repeatOn: availabilityInReviewToEdit?.recurrenceSettings?.repeatOn || [],
                repeatOnData: availabilityInReviewToEdit?.recurrenceSettings?.repeatOnData || [],
                endson: availabilityInReviewToEdit?.recurrenceSettings?.endson || true,
                makeCustomAvailabilityRecurring:
                  availabilityInReviewToEdit?.recurrencePattern != null ||
                  availabilityInReviewToEdit?.recurrenceSettings != null
                    ? true
                    : false,
                occurrences: null,
                occurrence: false,
                daysOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek
                  ? getDaysFromNumbers(availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek)
                  : [],
                isDayOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.isDayOfWeek || false,
                dayOfMonth: availabilityInReviewToEdit?.recurrenceSettings?.dayOfMonth || 1,
                weekOfMonth: availabilityInReviewToEdit?.recurrenceSettings?.weekOfMonth || 1,
                dayOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.dayOfWeek || 'Sunday',
                isResubmissionVal:
                  availabilityInReviewToEdit?.recurrencePattern != null ||
                  availabilityInReviewToEdit?.recurrenceSettings != null
                    ? true
                    : false,
                endDate: availabilityInReviewToEdit?.recurrenceSettings?.endDate
                  ? moment(availabilityInReviewToEdit.recurrenceSettings.endDate)
                  : moment().add(1, 'year'),
              }}
              validationSchema={Yup.object().shape({
                startTime: Yup.string().required('This is a required field').nullable(),
                endTime: Yup.string().required('This is a required field').nullable(),
                interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
                recurrencePattern: Yup.string().required('This is a required field'),
                date: Yup.string().required('This is a required field').nullable(),
                daysOfWeek: Yup.array().when('recurrencePattern', {
                  is: v => v === 'weekly',
                  then: Yup.array().required('This is a required field'),
                  otherwise: Yup.array(),
                }),
                repeatOn: Yup.array().when('recurrencePattern', {
                  is: v => v === 'monthly',
                  then: Yup.array().required('This is a required field'),
                  otherwise: Yup.array(),
                }),
                dayOfMonth: Yup.string().when('recurrencePattern', {
                  is: v => v === 'monthly',
                  then: Yup.string().required('This is a required field'),
                  otherwise: Yup.string(),
                }),
                dayOfWeek: Yup.string().when('recurrencePattern', {
                  is: v => v === 'monthly',
                  then: Yup.string().required('This is a required field'),
                  otherwise: Yup.string(),
                }),
                weekOfMonth: Yup.string().when('recurrencePattern', {
                  is: v => v === 'monthly',
                  then: Yup.string().required('This is a required field'),
                  otherwise: Yup.string(),
                }),
              })}
              onSubmit={handleEditsubmit}
            >
              {({ values, setFieldValue, errors, touched }) => {
                const changeRepeatOnValues = e => {
                  if (e?.target?.value === 'daily') {
                    setFieldValue('recurrencePattern', e?.target?.value);
                  } else if (e?.target?.value === 'weekly') {
                    setFieldValue('repeatOnData', WeekValues);
                    setFieldValue('daysOfWeek', []);
                    setFieldValue('recurrencePattern', e?.target?.value);
                  } else if (e?.target?.value === 'monthly') {
                    const todayDay = moment(moment(values.date)).format('dddd');
                    const todayDate = moment(moment(values.startTime)).format('D');
                    const count = findCountOf(todayDate);
                    var year = new Date(moment()).getFullYear();
                    var month = new Date(moment()).getMonth();
                    var day = todayDate;
                    let lastWeek;
                    if (isLastWeekOfMonth(year, month, day)) {
                      lastWeek = true;
                      console.log('Selected date is in the last week of the month.');
                    } else {
                      lastWeek = false;
                      console.log('Selected date is not in the last week of the month.');
                    }
                    setFieldValue('repeatOnData', [
                      { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                      {
                        title: `Monthly on ${
                          count === 1
                            ? '1st'
                            : count === 2
                            ? '2nd'
                            : count === 3
                            ? '3rd'
                            : count === 4 && lastWeek
                            ? 'Last'
                            : count === 5
                            ? 'Last'
                            : 'Last'
                        } ${todayDay}`,
                        value: `Monthly on ${count} ${todayDay}`,
                      },
                    ]);
                    setFieldValue('repeatOn', []);
                    setFieldValue('dayOfWeek', todayDay);
                    setFieldValue('weekOfMonth', count);
                    setFieldValue('recurrencePattern', e?.target?.value);
                  } else {
                    setFieldValue('repeatOnData', null);
                    setFieldValue('repeatOn', []);
                    setFieldValue('recurrencePattern', e?.target?.value);
                  }
                };
                const sortedArr1 = values?.daysOfWeek?.slice()?.sort(); // Sort a copy of arr1
                const sortedArr2 = availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek
                  ? getDaysFromNumbers(availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek).slice().sort()
                  : [];
                console.log(
                  'availabilityInReviewToEdit?.recurrenceSettings',
                  availabilityInReviewToEdit?.recurrenceSettings,
                );
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '550px' }}>
                    <div style={{ marginBottom: '10px' }}>
                      {`The available time slots for this change will be determined based on your specified time increment
                    of ${activeContribution?.oneToOneSessionDataUi?.sessionDuration} minutes.`}
                    </div>
                    {mobileView && (
                      <Grid style={{ marginBottom: '10px' }} lg={4} xs={12} sm={12}>
                        <Grid className={classes.heading} lg={12}>
                          Date
                        </Grid>
                        <Grid lg={12} style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                          <DatePicker
                            inputRef={datetimeRefSecond}
                            fullWidth
                            disablePast
                            onChange={momentDate => setFieldValue('date', moment(momentDate).format('MMMM DD,YYYY'))}
                            value={values.date}
                            format={'MMM DD, YYYY'}
                            inputVariant="outlined"
                          />
                          <InsertInvitationIcon
                            htmlColor="#757575"
                            style={{ position: 'absolute', right: '4px', top: '10px' }}
                            onClick={handleClickSecond}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      lg={12}
                      xs={12}
                      sm={12}
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: mobileView ? '5px' : '16px',
                      }}
                    >
                      {!mobileView && (
                        <Grid lg={4} xs={10} sm={10}>
                          <Grid className={classes.heading} lg={12}>
                            Date
                          </Grid>
                          <Grid lg={12} style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                            <DatePicker
                              inputRef={datetimeRefSecond}
                              fullWidth
                              disablePast
                              onChange={momentDate => setFieldValue('date', moment(momentDate).format('MMMM DD,YYYY'))}
                              value={values.date}
                              format={'MMM DD, YYYY'}
                              inputVariant="outlined"
                            />

                            <InsertInvitationIcon
                              htmlColor="#757575"
                              style={{ position: 'absolute', right: '4px', top: '10px' }}
                              onClick={handleClickSecond}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid className={classes.heading} lg={12}>
                          Start Time
                        </Grid>
                        <Grid lg={12}>
                          <TimePickerField
                            onChange={value => setFieldValue('startTime', moment(value))}
                            showSecond={false}
                            value={values.startTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                            style={{
                              fontFamily: 'Avenir',
                              border: '1px solid #e7e7e7',
                              fontWeight: 350,
                              fontSize: '16px',
                              borderRadius: '4px',
                              alignItems: 'center',
                              padding: '21px 5px',
                            }}
                            className="custom-time-picker-nounderline"
                          />
                          {editRecurringFormRef.current != null && touched.startTime && errors?.startTime && (
                            <CommonErrorMessage
                              className="default-font-family"
                              message={errors?.startTime}
                              align="left"
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid className={classes.heading} lg={12}>
                          End Time
                        </Grid>
                        <Grid lg={12}>
                          <TimePickerField
                            onChange={value => setFieldValue('endTime', moment(value))}
                            showSecond={false}
                            value={values.endTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: 350,
                              fontSize: '16px',
                              border: '1px solid #e7e7e7',
                              borderRadius: '4px',
                              alignItems: 'center',
                              padding: '21px 5px',
                            }}
                            className="custom-time-picker-nounderline"
                          />
                          {errors?.endTime && (
                            <CommonErrorMessage
                              className="default-font-family"
                              message={errors?.endTime}
                              align="left"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {editEntireSeries && (
                      <Grid style={{ marginTop: '10px', marginLeft: '20px' }} lg={12}>
                        <FormControlLabel
                          classes={{
                            label: classes.checkboxLabel,
                            root: classes.root,
                          }}
                          checked={values.isResubmissionVal}
                          onChange={() => {
                            setFieldValue('isResubmissionVal', !values.isResubmissionVal);
                            setFieldValue('occurrences', null);
                            const todayDay = moment(moment()).format('dddd');
                            const todayDate = moment(moment()).format('D');
                            const count = findCountOf(todayDate);
                            var year = new Date(moment()).getFullYear();
                            var month = new Date(moment()).getMonth() + 1;
                            var day = todayDate;
                            let lastWeek;
                            if (isLastWeekOfMonth(year, month, day)) {
                              lastWeek = true;
                              console.log('Selected date is in the last week of the month.');
                            } else {
                              lastWeek = false;
                              console.log('Selected date is not in the last week of the month.');
                            }
                            if (values.recurrencePattern === 'monthly') {
                              setFieldValue('repeatOnData', [
                                { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                                {
                                  title: `Monthly on ${
                                    count === 1
                                      ? '1st'
                                      : count === 2
                                      ? '2nd'
                                      : count === 3
                                      ? '3rd'
                                      : count === 4 && lastWeek
                                      ? 'Last'
                                      : count === 5
                                      ? 'Last'
                                      : '4th'
                                  } ${todayDay}`,
                                  value: `Monthly on ${count} ${todayDay}`,
                                },
                              ]);
                            } else {
                              setFieldValue('repeatOnData', WeekValues);
                            }
                          }}
                          disabled={true}
                          control={<Checkbox color="primary" />}
                          label="Make Availability Recurring"
                          name="isResubmissionVal"
                        />
                      </Grid>
                    )}
                    {values.isResubmissionVal && editEntireSeries && (
                      <>
                        <Grid
                          style={{
                            flexDirection: IsMasterCalendarModal || mobileView ? 'column' : 'row',
                            display: 'flex',
                            gap: mobileView ? '5px' : '16px',
                          }}
                          lg={12}
                        >
                          <Grid lg={IsMasterCalendarModal ? 12 : 4}>
                            <Grid className={classes.heading}>Repeat Every</Grid>
                            <Grid style={{ display: 'flex', gap: '8px' }} lg={12}>
                              <Grid lg={IsMasterCalendarModal ? 6 : 4} sm={12} xs={12}>
                                <StyledTextField
                                  style={{ fontFamily: 'Brandon Text', border: 'none' }}
                                  name="interval"
                                  value={values?.interval}
                                  fullWidth
                                  onChange={e => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value.toString() === '') {
                                      setFieldValue('interval', null);
                                    } else if (re.test(e?.target?.value)) {
                                      setFieldValue('interval', parseInt(e?.target?.value));
                                    }
                                  }}
                                  recurringField={true}
                                  error={errors && errors?.interval}
                                />
                              </Grid>
                              <Grid lg={IsMasterCalendarModal ? 6 : 8} sm={12} xs={12}>
                                <StyledCustomSelect
                                  showHelperText={false}
                                  style={{ fontFamily: 'Brandon Text' }}
                                  variant="outlined"
                                  name="recurrencePattern"
                                  defaultValue={values?.recurrencePattern}
                                  fullWidth
                                  onChange={changeRepeatOnValues}
                                  className="custom-input-style"
                                  items={[
                                    { value: 'daily', title: values?.interval > 1 ? 'Days' : 'Day' },
                                    {
                                      value: 'weekly',
                                      title: values?.interval > 1 ? 'Weeks' : 'Week',
                                    },
                                    {
                                      value: 'monthly',
                                      title: values?.interval > 1 ? 'Months' : 'Month',
                                    },
                                    {
                                      value: 'yearly',
                                      title: values?.interval > 1 ? 'Years' : 'Year',
                                    },
                                  ]}
                                  roundedBorder={true}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            style={{ display: 'flex', gap: IsMasterCalendarModal || mobileView ? '8px' : '16px' }}
                            lg={IsMasterCalendarModal || mobileView ? 12 : 8}
                          >
                            {(values?.recurrencePattern === 'weekly' || values?.recurrencePattern === 'monthly') && (
                              <Grid lg={6} sm={6} xs={6}>
                                <Grid className={classes.heading}>Repeat On</Grid>
                                <Grid container xs={12} sm={12}>
                                  {values.recurrencePattern === 'weekly' ? (
                                    <StyledCustomSelect
                                      showHelperText={false}
                                      underLineColor={'transparent'}
                                      name="daysOfWeek"
                                      multiple
                                      className="custom-input-style"
                                      items={WeekValues}
                                      fullWidth={mobileView ? false : true}
                                      style={{ marginTop: '0px' }}
                                      roundedBorder={true}
                                    />
                                  ) : (
                                    <>
                                      <StyledCustomSelect
                                        showHelperText={false}
                                        underLineColor={'transparent'}
                                        name="repeatOn"
                                        style={{ marginTop: '0px' }}
                                        className="custom-input-style"
                                        fullWidth
                                        value={values?.repeatOn}
                                        roundedBorder={true}
                                        onChange={e => {
                                          const todayDay = moment(moment(values.date)).format('dddd');
                                          const todayDate = moment(moment(values.startTime)).format('D');
                                          if (e?.target?.value === `Monthly on day ${todayDate}`) {
                                            setFieldValue('isDayOfWeek', false);
                                            setFieldValue('dayOfMonth', parseInt(todayDate));
                                          } else {
                                            const count = findCountOf(todayDate);
                                            setFieldValue('isDayOfWeek', true);
                                            setFieldValue('dayOfWeek', todayDay);
                                            setFieldValue('weekOfMonth', parseInt(count));
                                          }
                                          setFieldValue('repeatOn', [e?.target?.value]);
                                        }}
                                        items={values?.repeatOnData}
                                      />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            <Grid lg={6}>
                              <div style={{ display: 'flex', flexDirection: 'column' }} className="default-font-family">
                                <div className={classes.heading}>End Date</div>
                                <div>
                                  <div
                                    style={{
                                      position: 'relative',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <DatePicker
                                      inputRef={datetimeRef}
                                      fullWidth
                                      disablePast
                                      onChange={momentDate => handleDatePickRecurringEdit(momentDate)}
                                      value={values.endDate}
                                      format={'MMM DD, YYYY'}
                                      TextFieldComponent={props => <StyledTextField {...props} />}
                                    />
                                    <div style={{ cursor: 'pointer' }}>
                                      <DropdownIcon width="12" height="7" color="#00000066" availability={true} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <div style={{ display: 'flex', gap: '10px', padding: '15px 0px' }}>
                      <Button
                        invert
                        autoWidth
                        className={classes.cancelBtn}
                        onClick={() => {
                          setEditSelectedOccurrence(false);
                          dispatch(setCampaignNotification({}));
                          setAvailabilityInReviewToEdit(null);
                          setEditEntireSeries(false);
                          setEditSelectedOccurrence(false);
                          setRecurrencePattern(null);
                          setEditEntireSeries(false);
                        }}
                      >
                        Cancel
                      </Button>
                      {editEntireSeries ? (
                        <Button
                          autoWidth
                          className={classes.doneBtn}
                          onClick={() => {
                            handleEditsubmit();
                          }}
                          disabled={
                            !values.startTime ||
                            !values.endTime ||
                            moment(moment(values.endDate).format('YYYY-MM-DD')).isBefore(
                              moment(moment(values.date).format('YYYY-MM-DD')).format('YYYY-MM-DD'),
                            ) ||
                            moment(moment(values.endDate).format('YYYY-MM-DD')).isSame(
                              moment(moment(values.date).format('YYYY-MM-DD')).format('YYYY-MM-DD'),
                            ) ||
                            moment(values.endTime).format('HH:mm:ss') === moment(values.startTime).format('HH:mm:ss') ||
                            (moment(values.date).format('MMMM DD, YYYY') ===
                              moment(availabilityInReviewToEdit?.startTime).format('MMMM DD, YYYY') &&
                              moment(values.startTime).format('MMMM DD, YYYY, h:mm A') ===
                                moment(availabilityInReviewToEdit?.startTime).format('MMMM DD, YYYY, h:mm A') &&
                              moment(values.endTime).format('MMMM DD, YYYY, h:mm A') ===
                                moment(availabilityInReviewToEdit?.endTime).format('MMMM DD, YYYY, h:mm A') &&
                              values.interval === availabilityInReviewToEdit.recurrenceSettings.interval &&
                              values.recurrencePattern ===
                                availabilityInReviewToEdit.recurrenceSettings.recurrencePattern &&
                              moment(values.endDate).format('MMMM DD, YYYY') ===
                                moment(availabilityInReviewToEdit.recurrenceSettings.endDate).format('MMMM DD, YYYY') &&
                              values.repeatOn[0] === availabilityInReviewToEdit.recurrenceSettings.repeatOn[0] &&
                              values.daysOfWeek.length ===
                                availabilityInReviewToEdit.recurrenceSettings.daysOfWeek.length &&
                              sortedArr1.every((value, index) => value === sortedArr2[index]))
                          }
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          autoWidth
                          className={classes.doneBtn}
                          onClick={() => {
                            handleEditsubmit();
                          }}
                          disabled={
                            !values.startTime ||
                            !values.endTime ||
                            moment(values.endTime).format('HH:mm:ss') === moment(values.startTime).format('HH:mm:ss') ||
                            (moment(values.date).format('MMMM DD, YYYY') ===
                              moment(availabilityInReviewToEdit?.startTime).format('MMMM DD, YYYY') &&
                              moment(values.startTime).format('MMMM DD, YYYY, h:mm A') ===
                                moment(availabilityInReviewToEdit?.startTime).format('MMMM DD, YYYY, h:mm A') &&
                              moment(values.endTime).format('MMMM DD, YYYY, h:mm A') ===
                                moment(availabilityInReviewToEdit?.endTime).format('MMMM DD, YYYY, h:mm A') &&
                              values.endTime)
                          }
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Modal>
        )}

        {showAllOccurrencesModal && (
          <Modal
            title="All Occurrences"
            isOpen={showAllOccurrencesModal}
            onCancel={() => {
              setRecurrencePattern(null);
              setShowAllOccurrencesModal(false);
              setEditOccurrencesRow(null);
              setEditOccurrencesRowData(null);
              setRecurringCustomId(null);
              dispatch(setCampaignNotification({}));
              setallOccurencesModal(false);
            }}
            disableOverlayClick
            isCreatingContribution={true}
            brandingColor={false}
            minWidth={mobileView ? '300px' : '720px'}
            max-width={mobileView ? '300px' : '600px'}
            disableFooter={true}
          >
            <>
              {!isEmpty(campaignNotification) && showAllOccurrencesModal && (
                <Notification
                  notification={{
                    heading: mobileView ? 'Slot(s) Updated!' : 'Availability slot(s) updated successfully!',
                  }}
                  slotsUpdation={true}
                  component={true}
                  isModal={true}
                />
              )}
              <div>
                {customContributionTimes
                  .filter(item => item.customAvailabilityId === recurringCustomId)
                  ?.map((item, index) => {
                    if (item.isCustomSlot)
                      return (
                        <AllOccurrencesRow key={index}>
                          {editOccurrencesRow !== index ? (
                            <>
                              <StyledDateView>
                                <StyledDot />
                                <OccurencesDateTime>{`${moment(item.startTime).format('MM/DD/YYYY')},  ${moment(
                                  item.startTime,
                                ).format('hh:mm a')} - ${moment(item.endTime).format('hh:mm a')}`}</OccurencesDateTime>
                              </StyledDateView>
                              <OccurencesActions>
                                <StyledEditIcon
                                  onClick={() => {
                                    setEditOccurrencesRowData({ ...item, isModal: true });
                                    setEditOccurrencesRow(index);
                                  }}
                                >
                                  <EditIcon color="#215C73" width="18px" height="18px" />
                                </StyledEditIcon>
                                <StyledCloseIcon
                                  onClick={() => {
                                    handleDeleteAvailiability({ ...item, isModal: true });
                                  }}
                                >
                                  <DeleteIcon />
                                </StyledCloseIcon>
                              </OccurencesActions>
                            </>
                          ) : (
                            <>
                              <Formik
                                innerRef={editSingleRecurringFormRef}
                                initialValues={{
                                  contributions: activeContribution?.id || '',
                                  startTime: moment(item?.startTime || new Date()),
                                  endTime: moment(item?.endTime || new Date()),
                                  date: moment(item?.startTime || new Date()).format('MMMM DD, YYYY'),
                                }}
                                validationSchema={Yup.object().shape({
                                  startTime: Yup.string().required('This is a required field').nullable(),
                                  endTime: Yup.string().required('This is a required field').nullable(),
                                })}
                                onSubmit={values => {}}
                              >
                                {({ values, setFieldValue, errors, touched, handleSubmit }) => (
                                  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '550px' }}>
                                    <Grid
                                      lg={12}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        gap: mobileView ? '5px' : '16px',
                                      }}
                                    >
                                      <Grid lg={4}>
                                        <Grid className={classes.heading} lg={12}>
                                          Date
                                        </Grid>
                                        <Grid
                                          lg={12}
                                          style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}
                                        >
                                          <DatePicker
                                            inputRef={datetimeRef}
                                            fullWidth
                                            disablePast
                                            onChange={momentDate => setFieldValue('date', moment(momentDate))}
                                            value={values.date}
                                            format={'MMM DD, YYYY'}
                                            inputVariant="outlined"
                                          />
                                          {mobileView === false && (
                                            <InsertInvitationIcon
                                              htmlColor="#757575"
                                              style={{ position: 'absolute', right: '4px', top: '10px' }}
                                              onClick={handleClick}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Grid className={classes.heading} lg={12}>
                                          Start Time
                                        </Grid>
                                        <Grid lg={12}>
                                          <TimePickerField
                                            onChange={value => {
                                              setFieldValue('startTime', moment(value));
                                              editSingleRecurringFormRef.current.setErrors({});
                                            }}
                                            showSecond={false}
                                            value={values.startTime}
                                            format="h:mm a"
                                            use12Hours
                                            minuteStep={15}
                                            style={{
                                              fontFamily: 'Avenir',
                                              border: '1px solid #e7e7e7',
                                              fontWeight: 350,
                                              fontSize: '16px',
                                              borderRadius: '4px',
                                              alignItems: 'center',
                                              padding: '21px 5px',
                                            }}
                                            className="custom-time-picker-nounderline"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Grid className={classes.heading} lg={12}>
                                          End Time
                                        </Grid>
                                        <Grid lg={12}>
                                          <TimePickerField
                                            onChange={value => setFieldValue('endTime', moment(value))}
                                            showSecond={false}
                                            value={values.endTime}
                                            format="h:mm a"
                                            use12Hours
                                            minuteStep={15}
                                            style={{
                                              fontFamily: 'Avenir',
                                              fontWeight: 350,
                                              fontSize: '16px',
                                              border: '1px solid #e7e7e7',
                                              borderRadius: '4px',
                                              alignItems: 'center',
                                              padding: '21px 5px',
                                            }}
                                            className="custom-time-picker-nounderline"
                                          />
                                          {editSingleRecurringFormRef.current != null && errors?.endTime && (
                                            <CommonErrorMessage
                                              className="default-font-family"
                                              message={errors?.endTime}
                                              align="left"
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <div style={{ display: 'flex', gap: '10px', paddingTop: '15px' }}>
                                      <Button
                                        autoWidth
                                        className={classes.cancelBtn}
                                        onClick={() => {
                                          setEditOccurrencesRowData(null);
                                          setEditOccurrencesRow(null);
                                          dispatch(setCampaignNotification({}));
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          if (showAllOccurrencesModal === true) {
                                            setallOccurencesModal(true);
                                          } else {
                                            setallOccurencesModal(false);
                                          }
                                          setShowLoader(true);
                                          editSingleRecurringFormRef.current.setErrors({});
                                          const date = moment(values.date).format('YYYY-MM-DD');
                                          const startDay = moment(
                                            `${date} ${values.startTime.format('HH:mm:00')}`,
                                          ).format('YYYY-MM-DDTHH:mm:00');
                                          const endDay = moment(`${date} ${values.endTime.format('HH:mm:00')}`).format(
                                            'YYYY-MM-DDTHH:mm:00',
                                          );
                                          if (startDay > endDay || startDay === endDay) {
                                            setShowLoader(false);
                                            editSingleRecurringFormRef.current.setErrors({
                                              endTime: 'End time must be later than start time.',
                                            });
                                          } else {
                                            editSingleRecurringFormRef.current.setErrors({});
                                            const dataToUpdate = {
                                              contributionIds: IsMasterCalendarModal
                                                ? masterCalendarArray.map(item => item.id).join(', ')
                                                : values.contributions,
                                              dateTime: item.startTime,
                                              sessionDuration: sessionDuration || 60,
                                              newTimeRange: { endTime: endDay, startTime: startDay },
                                              isDelete: false,
                                              customAvailabilityId: item.customAvailabilityId,
                                            };
                                            updateCustomAvailability(dataToUpdate)
                                              .then(res => {
                                                if (res.includes('Overlapping slots found')) {
                                                  setOverlappingAllowed(true);
                                                } else {
                                                  dispatch(
                                                    setCampaignNotification({
                                                      message: 'test',
                                                      heading: 'test',
                                                      isModal: true,
                                                    }),
                                                  );
                                                  if (IsMasterCalendarModal && masterCalendarArray.length === 1) {
                                                    dispatch(fetchContributionTimes(masterCalendarArray[0].id, true));
                                                    dispatch(
                                                      fetchCustomContributionTimes(
                                                        masterCalendarArray[0].id || activeContribution.id,
                                                        activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                                                        activeContribution?.oneToOneSessionDataUi?.sessionDuration,
                                                      ),
                                                    );
                                                  }
                                                }
                                                setShowLoader(false);
                                                setEditOccurrencesRowData(null);
                                                setEditOccurrencesRow(null);
                                                incrementNewSlots();
                                                setTimeout(() => {
                                                  setallOccurencesModal(false);
                                                }, 5000);
                                              })
                                              .catch(err => {
                                                setShowLoader(false);
                                                console.error('Update failed:', err);
                                                setallOccurencesModal(false);
                                              });
                                          }
                                        }}
                                        autoWidth
                                        className={classes.doneBtn}
                                        disabled={
                                          !values.startTime ||
                                          !values.endTime ||
                                          (moment(values.date).format('MMMM DD, YYYY') ===
                                            moment(item?.startTime).format('MMMM DD, YYYY') &&
                                            moment(values.startTime).format('MMMM DD, YYYY, h:mm A') ===
                                              moment(item?.startTime).format('MMMM DD, YYYY, h:mm A') &&
                                            moment(values.endTime).format('MMMM DD, YYYY, h:mm A') ===
                                              moment(item?.endTime).format('MMMM DD, YYYY, h:mm A'))
                                        }
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </>
                          )}
                        </AllOccurrencesRow>
                      );
                    else return null;
                  })}
              </div>
            </>
          </Modal>
        )}

        {overlappingAllowed && (
          <Modal
            isCreatingContribution={true}
            brandingColor={false}
            isOpen={true}
            title="Slot Conflict"
            submitTitle={'OK'}
            // cancelTitle={'No'}
            onCancel={() => {
              setOverlappingAllowed(false);
            }}
            disableOverlayClick
            hiddenCancel={true}
            onSubmit={() => {
              setOverlappingAllowed(false);
              incrementNewSlots();
              setOverlappingAllowed(false);
            }}
          >
            <div className="d-flex flex-column">
              This time slot overlaps with your schedule. Please choose a different time.
            </div>
          </Modal>
        )}
      </>
    );
  },
);

AvailabilityRecurring.propTypes = {
  onCancel: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};

AvailabilityRecurring.defaultProps = {
  applyTheming: false,
};
