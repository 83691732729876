import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAccount, useHeader, useRouter, useHttp, useChangedProps } from 'hooks';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ContributionStatus, ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';
import * as contributionActions from 'actions/contributions';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from 'utils/styles';
import {
  determineColorToUse,
  determineDarkThemedColorToUse,
  getThemedColors,
  updateApplicationFormPreviewStatus,
  getFreeAccessCode,
  getClientContributionApi,
  contributionParticipants,
} from 'services/contributions.service';
import * as pageActions from 'actions/page';
import * as userActions from 'actions/user';
import { Formik, Field, Form, yupToFormErrors } from 'formik';
import { lightOrDark } from 'utils/utils';
import { Icon } from '@mdi/react';
import { mdiLoading, mdiWeatherSunny, mdiTimerSandEmpty, mdiEyeCircleOutline } from '@mdi/js';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

import { EN_MESSAGES, PAYMENT_OPTIONS, VALIDATION_REGEX } from 'constants.js';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  fetchContributionTimes,
  resetContribution,
  firstTimeRedirect,
} from 'actions/contributions';
import Loader from 'components/UI/Loader';
import * as Yup from 'yup';
import { DialogActions, Box, TextField } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Header from 'components/UI/Header';
import Modal from 'components/UI/Modal';
import HeaderMobile from 'components/UI/HeaderMobile';
import { TOOLTIP } from '../../constants.js';
import ContributionHeader from 'pages/ContributionView/components/ContributionHeader';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import * as R from 'ramda';
import {
  AboutContribution,
  ContributionDiscussion,
  ContributionNotes,
  ContributionReviews,
  ContributionSessions,
  ContributionTeam,
  SecurityContribution,
  UserResources,
} from 'pages';
import { devices } from 'utils/styles';
import { parseQuery } from 'utils/parseData';
import { LabelText } from 'components/UI/Text/TextStyles';
import SEO from 'components/Seo/Seo';
import { ROUTES } from '../../constants';
import ContributionCommunity from '../../pages/ContributionView/ContributionCommunity/ContributionCommunity';
import TemplateOne from 'pages/ContributionView/Templates/TemplateOne';
import TemplateTwo from 'pages/ContributionView/Templates/TemplateTwo';
import HeaderTempOne from 'components/UI/HeaderTempOne';
import HeaderTempTwo from 'components/UI/HeaderTempTwo';
import { TemplateType } from 'helpers/constants/templateType';
import CustomPurchaseBlock from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlock';
import { PAYMENTS_STATUS } from 'pages/ContributionView/components/PurchaseMembershipModal/PurchaseModal.constants';
import CustomPurchaseBlockOneToOne from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlockOneToOne';
import PaymentCard from 'pages/ContributionView/components/PaymentCard';
import { User } from 'twilio-chat';
import ContributionCommunication from 'pages/ContributionView/ContributionCommunication/ContributionCommunication';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import ThankYouPreviewPage from './ThankYouPreviewPage';
import { ApplicationFormBanner } from 'components/UI/ContributionHeaderView';
import { ClientPreviewBanner } from 'components/UI/ContributionHeaderView';
import CreateApplicationFormModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/CreateApplicationFormModal';
import { updateUser } from 'actions/update-user';
import isEmpty from 'lodash/isEmpty';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
import ContributionCalendars from 'pages/ContributionView/ContributionCalendar';
import { redirectTo } from 'services/links';
import moment from 'moment';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../constants';
import { TRIAL_PERIOD_DAYS, TRIAL_PERIOD_START_DATE } from 'components/Tables/Contribution';
import { getCohealerContribution, shareViaEmail } from 'services/contributions.service';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import { isEqual } from 'lodash';
import ApprovedPost from 'pages/ContributionView/ContributionSocial/components/ApprovedPost/index.js';
import { setCookie } from 'services/cookies.service.js';

const StyledMainContainer = styled.div`
  background-color: #fafafa;
  background-color: ${props => props.themedBackgroundColor};
  color: ${props => props.themedColor};
  min-height: 100vh;
  display: flex;
`;

const StyledWrapper = styled.div`
  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    `
   disabled: true;
  `}
`;

const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StickyPortion = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
  position: sticky;
  top: 0;
  z-index: 20;  
`}
`;
const StyledContactInfo = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: #000000de;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 400px;
  padding: 4px 5px 15px 0px;
`;
const StyledEmail = styled.a`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  text-decoration: underline;
  color: #000000de;
  cursor: pointer;
`;

const StyledMainSection = styled.div`
  flex-grow: 1;
  background-color: ${({ activeTemplate, isCoach, type }) =>
    activeTemplate === TemplateType.TemplateThree || isCoach ? '#fafafa' : 'white'};
  background-color: ${({ themedBackgroundColor, isCommunity, isDarkModeEnabled }) =>
    isCommunity && !isDarkModeEnabled ? 'white' : themedBackgroundColor};

  padding: ${({
    activeTemplate,
    isCoach,
    type,
    devModeSessionsTab,
    isSessionsTab,
    isAboutPagePath,
    isNonOneToOne,
    isCommunity,
  }) =>
    isCommunity
      ? '24px 16px'
      : activeTemplate === TemplateType.TemplateThree || isCoach
      ? '16px 16px'
      : activeTemplate === TemplateType.TemplateTwo
      ? '0px 16px'
      : !isCoach && devModeSessionsTab && isSessionsTab && !isAboutPagePath && isNonOneToOne
      ? 0
      : '16px 48px'};

  padding-top: ${({ isSticky, type }) => (isSticky ? '0px' : '')};
  padding: ${({ xsView, mobileView }) => (xsView && '0') || (mobileView && '8px')};
  ${({ isLoggedIn, devModeSessionsTab, isSessionsTab, isNonOneToOne, isPurchased, isCoach }) =>
    devModeSessionsTab &&
    isLoggedIn &&
    isSessionsTab &&
    isNonOneToOne &&
    (isPurchased || isCoach) &&
    css`
      padding-left: 0;
      padding-right: 0;
      padding: 0;
    `}

  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    `
    pointer-events: none;
  `}

  ${({ isCommunicationTab }) =>
    isCommunicationTab &&
    `
    display: flex;
    flex-direction: column;
  `}
`;

const ContributionViewMainContainer = props => {
  const {
    children,
    handleShareButtonClick,
    contribution,
    getClientContribution,
    getCohealerContribution,
    error,
    currentRole,
    getContributionTimes,
    account,
    inviteCode,
    startTimeIncrementDuration,
    sessionDuration,
  } = props;

  const { request } = useHttp();
  let colorToUse = determineColorToUse(contribution);
  const [fetchParticipant, setFetchParticipant] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const textColorAccent =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const useStyles = makeStyles(theme => ({
    drawer: {
      '& .MuiDrawer-paper': {
        backgroundColor: colorToUse?.PrimaryColorCode,
      },
    },
  }));
  const { user, isLoggedIn } = useAccount();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const [accessExpiredModal, setaccessExpiredModal] = useState(false);
  const [seoDescription, setSeoDescription] = useState('');
  const [noPasswordModalVisible, setNoPasswordModalVisible] = useState(false);
  const sessionTimeEditMode = useSelector(state => state?.contributions?.editMode);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const stickyHeaderRef = useRef();
  const isPurchased = contribution?.isPurchased;
  useHeader(contribution?.title || '');
  const {
    path,
    url,
    params: { id },
  } = useRouteMatch();
  const { history, location, query, domain, pathname, parentDomain } = useRouter();
  const clientPreviewMode = location?.pathname?.includes('clientpreview');
  const isCommunicationTab = location?.pathname?.includes('communication');
  const classes = useStyles();
  const isCoach = currentRole === UserRoles.cohealer;
  const dispatch = useDispatch();
  const { status } = useContribution();
  const firstLoad = useSelector(state => state.contributions?.firstLoad);
  const stickH = useSelector(state => state.stickyHeader?.stickyHeader);
  const isMobileSidebarOpen = useSelector(state => state.page.isMobileSidebarOpen);
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  const brandingColors = contribution?.brandingColors;
  const [isOpenPasswordPopUp, setIsOpenPasswordPopUp] = useState(
    user?.isPasswordNotCreated || user?.account?.isPasswordNotCreated,
  );
  const [showApplicationFormPopup, setShowApplicationFormPopup] = useState(false);
  const [showApplicationFormBanner, setShowApplicationFormBanner] = useState(true);
  const devModeSessionsTab = true; // pathname?.includes('/sessions/dev') || false;
  const setBannerPreviewedStatusInContribution = () => {
    updateApplicationFormPreviewStatus(contribution.id).then(data => {
      setShowApplicationFormBanner(false);
    });
  };
  const {
    themedColor: themedTextColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedBackgroundColor,
    newThemedTextColor,
  } = getThemedColors(contribution, isCoach);

  const themedColor = isCoach && !clientPreviewMode ? themedTextColor : newThemedTextColor;
  const themedBackgroundColor = isCoach && !clientPreviewMode ? oldThemedBackgroundColor : newThemedBackgroundColor;
  const calendarTab = pathname?.includes('calendar');
  const initialValues = {
    Password: '',
  };
  const [showApplicationCreatedPopup, setShowApplicationCreatedPopup] = useState(false);
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const todayDate = moment(moment());
  const [openingModal, setOpeningModal] = useState(false);
  const [contributionId, setContributionId] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const parts = location.pathname.split('/');
  const pValue = parts.find(part => part.startsWith('p_'));
  const [isLoading, setLoading] = useState(false);
  const handleModalOpen = e => {
    setLoading(true);
    if (
      isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd)
    ) {
      return;
    }
    handleShareButtonClick(e);
    // setOpeningModal(true);
    setShowApplicationCreatedPopup(false);
    // setModalOpened(true);
  };
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  const handleCloseModal = () => {
    setModalVisible(false);
    redirectToAppIfIsCoachDomain(domain, '/dashboard');
  };

  // const handleCloseAccessModal = () => {
  //   setaccessExpiredModal(false);
  //   redirectToAppIfIsCoachDomain(domain, '/dashboard');
  // };

  const switchRolesIfClient = async () => {
    await getClientContributionApi(id, false, pValue?.includes('p_') ? pValue : null).then(res => {
      const anss = res?.contributionPartners?.filter(x => x?.userId === user?.id);
      if (anss?.length > 0) {
        history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
      }
      if (res?.userId === user?.id) {
        history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
      }
    });
  };

  useEffect(() => {
    if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id)
        .then(res => {
          let u = {
            ...user,
            userProgressbarData: res.payload.userProgressbarData,
            progressBarPercentage: res.payload.progressBarPercentage,
          };
          dispatch(updateUser(u));
        })
        .catch(() => {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
        });
    } else if (currentRole === UserRoles?.client) {
      getClientContribution(id, false, pValue?.includes('p_') ? pValue : null);
      switchRolesIfClient();
    } else {
      getClientContribution(id, false, pValue?.includes('p_') ? pValue : null);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);

  useEffect(() => {
    const currentPathName = window.location.pathname.includes('community') ? 'community' : 'sessions';
    if (contribution?.type === ContributionType.contributionOneToOne) {
      if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
        getContributionTimes(id, true, contribution?.sessionIncrements?.[0] || 0, contribution?.durations?.[0]);
        // getContributionTimes(id, true, startTimeIncrementDuration, sessionDuration);
      } else {
        getContributionTimes(id, false, contribution?.sessionIncrements?.[0] || 0, contribution?.durations?.[0]);
        // getContributionTimes(id, false, startTimeIncrementDuration, sessionDuration);
      }
    }
    if (firstLoad && contribution) {
      if (!query?.email?.length) {
        dispatch(firstTimeRedirect());
      }

      if (
        (contribution?.type === ContributionType.contributionCommunity || currentPathName === 'community') &&
        !query?.email?.length
      ) {
        const pathLength = window.location.pathname.split('/community');
        if (!clientPreviewMode && pathLength.length < 2) {
          if (
            contribution?.isPurchased &&
            contribution?.isThankyouPageAdded === true &&
            contribution?.isThankyouPageViewd === true &&
            isCoach === false
          ) {
          } else {
            if (contribution?.isCommunityFeatureHiddden === true) {
              history.push(`community/clients`);
            } else {
              if (contribution?.isPurchased) {
                history.push(`community`);
              }
            }
          }
        }
      } else if (
        (contribution?.type === ContributionType.contributionCourse ||
          contribution?.type === ContributionType.contributionMembership ||
          contribution?.type === ContributionType.contributionOneToOne) &&
        contribution.isPurchased
      ) {
        if (
          pathname.includes('/community') ||
          pathname.includes('/community/conversations') ||
          pathname.includes('/community/clients')
        ) {
        } else {
          if (pathname.includes('/notes') === false && !devModeSessionsTab) {
            history.push(`sessions`);
          }
        }
      }
    }
  }, [contribution]);

  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false);
  const [partnerContributionName, setPartnerContributionName] = useState(null);
  const editHandler = useCallback(
    values => {
      const { Password } = values;
      request(`/Account/UpdatePasswordandTimezone`, 'POST', {
        password: Password,
        // id: user.accountId,
        email: user.email,
      })
        .then(() => {
          request(`/Account/${user.accountId}`).then(R.compose(dispatch, userActions.getAccount));
          dispatch(userActions.userPasswordCreated());
          setIsOpenPasswordPopUp(false);
        })
        .catch(() => {});
    },
    [user],
  );
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (clientPreviewMode) {
  //     history.push(`about`);
  //   }
  // }, [clientPreviewMode]);

  useEffect(() => {
    const pathName = location.pathname.split('/');

    if (
      pathName?.length == 5 &&
      pathName[3]?.includes('s_') === true &&
      pathName[3]?.includes('p_') === false &&
      pathName[3]?.includes('clientpreview') === false
    ) {
      dispatch(contributionActions.setPriceId(null));
      dispatch(contributionActions.setInviteCode(null));
      dispatch(contributionActions.setSingleCode(pathName[3]));
    }
    if (
      pathName?.length == 5 &&
      pathName[3]?.includes('p_') === true &&
      pathName[3]?.includes('s_') === false &&
      pathName[3]?.includes('clientpreview') === false
    ) {
      dispatch(contributionActions.setPriceId(pathName[3]));
      dispatch(contributionActions.setInviteCode(null));
      dispatch(contributionActions.setSingleCode(null));
    }
    if (
      pathName.length == 5 &&
      pathName[3]?.includes('p_') === false &&
      pathName[3]?.includes('community') === false &&
      pathName[3]?.includes('clientpreview') === false &&
      pathName[3]?.includes('s_') === false &&
      pathName[3]?.includes('sessions') === false &&
      pathName.includes('GuestUserReschedule') === false
    ) {
      dispatch(contributionActions.setInviteCode(pathName[3]));
      dispatch(contributionActions.setPriceId(null));
      dispatch(contributionActions.setSingleCode(null));
    }
    if (isPurchased) {
      if (contribution?.isAccessRevokedByCoach === true && isCoach === false) {
        setModalVisible(true);
      }
      if (query.payment === PAYMENTS_STATUS.SUCCESS) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
        }
      }

      if (shouldRedirect) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          dispatch(contributionActions.setInviteCode(null));
          // history.push(`/contribution-view/${id}/sessions`);
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 1000);
        }
      }
    }
    // if (contribution?.isMembershipCancelled === true) {
    //   setaccessExpiredModal(true);
    // }
  }, [dispatch, history, contribution?.id, isPurchased, query?.payment, shouldRedirect, query?.code]);

  useEffect(() => {
    const parsedQuery = parseQuery(location.search);

    if (parsedQuery?.success) {
      // make sure the user is in coach view
      if (currentRole === UserRoles.client) {
        history.push(ROUTES.ROLE_SWITCH, { path: location.pathname + location.search });
      } else {
        const isSuccessfully = parsedQuery.success === 'true';
        setIsAddedSuccessfully(isSuccessfully);
        setPartnerContributionName(parsedQuery.contributionName);
        setIsShowPartnerModal(true);
      }
    }
  }, [history, location.pathname, location.search]);
  useEffect(() => {
    if (activeContribution != null && fetchParticipant === false) {
      dispatch(contributionActions.setLoadingParticipants(true));
      contributionParticipants(id).then(res => {
        let contrib = {
          ...contribution,
          participants: res,
        };
        setFetchParticipant(true);
        dispatch(contributionActions.fetchContributionActions.success(contrib));
        dispatch(contributionActions.setLoadingParticipants(false));
      });
    }
  }, [activeContribution]);

  const setAccessCode = async () => {
    const code = await getFreeAccessCode(contribution?.id);
    if (
      location?.pathname?.includes('/community/social/') === false &&
      location?.pathname?.includes('GuestUserReschedule') === false
    ) {
      history.push(`/contribution-view/${contribution?.id}/${code}/about`);
      window.location.reload();
    }
    // if (code) {
    //   dispatch(contributionActions.setInviteCode(code));
    // }
  };
  const priceId = useSelector(state => state.contributions.priceCode);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const redirectToSessions = useSelector(state => state?.contributions?.redirectToSessions);
  useEffect(() => {
    if (
      !isCoach &&
      !clientPreviewMode &&
      !contribution?.isPurchased &&
      contribution?.paymentInfo?.paymentOptions.length === 1 &&
      contribution?.paymentInfo?.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
      !priceId &&
      !isCodeToJoin &&
      !isInviteToJoin &&
      !redirectToSessions
    ) {
      setAccessCode();
    }
    if (
      !isCoach &&
      !clientPreviewMode &&
      !contribution?.isPurchased &&
      contribution?.paymentInfo?.paymentOptions.length === 2 &&
      contribution?.paymentInfo?.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
      contribution?.paymentInfo?.paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) &&
      !priceId &&
      !isCodeToJoin &&
      !isInviteToJoin &&
      !redirectToSessions
    ) {
      setAccessCode();
    }
  }, [
    isInviteToJoin,
    priceId,
    isCodeToJoin,
    isCoach,
    clientPreviewMode,
    contribution?.paymentInfo?.paymentOptions,
    contribution?.id,
    redirectToSessions,
  ]);

  useEffect(() => {
    if (contribution?.isPurchased === true) {
      dispatch(contributionActions.setRedirectToSessions(false));
    }
  }, [contribution?.isPurchased]);

  // useEffect(() => {
  //   console.info('u2:', stickyHeaderRef);
  // }, [stickyHeaderRef]);
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
    history.push(`${location.pathname}`);
  };

  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };

  const htmlToPlainText = html => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const textNodes = [];

    function extractTextNodes(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        textNodes.push(node.nodeValue);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        for (const childNode of node.childNodes) {
          extractTextNodes(childNode);
        }
      }
    }

    extractTextNodes(doc.body);

    return textNodes.join(' ').trim();
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (user?.isPasswordNotCreated === true && contribution?.isPurchased === true) {
      setIsOpenPasswordPopUp(true);
    }
  }, [user?.isPasswordNotCreated, contribution?.isPurchased]);

  useEffect(() => {
    let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    let isValid = regexForHTML.test(contribution?.whoIAm);
    if (isValid) {
      const plainText = htmlToPlainText(contribution?.whoIAm);
      setSeoDescription(plainText);
    } else {
      setSeoDescription(contribution?.whoIAm);
    }
  }, [contribution?.whoIAm]);
  useEffect(() => {
    if (sessionTimeEditMode) {
      dispatch(contributionActions.setSessionTimeEditMode(false));
    }
  }, []);

  const getShowLoginPasswordPopup = () => {
    if (user?.isSecondaryAccount || user?.isSecondaryExisting) {
      return false;
    }
    return user?.isPasswordNotCreated === true || user?.account?.isPasswordNotCreated === true;
  };

  useEffect(() => {
    if (
      !isEmpty(user) &&
      (user?.isSecondaryAccount || user?.isSecondaryExisting) &&
      !isEmpty(contribution) &&
      !history.location.pathname.includes('s_') &&
      !contribution?.isAccountNeeded
    ) {
      dispatch(userActions.setGuestContributionId(contribution.id));
      const pDomain = parentDomain.split(':3000')[0];
      setCookie('guestContributionId', contribution.id, pDomain, 30);
      if (contribution?.isPurchased && !pathname?.toLowerCase()?.includes('/sessions')) {
        return history.push(`/contribution-view/${contribution.id}/sessions`);
      }
    }
  }, [user, contribution]);

  if (!contribution?.id) {
    return <Loader />;
  }

  const isFinalized = status === ContributionStatus.rejected;
  const isContributionPurchased = contribution.isPurchased;
  const coachCanAccess = currentRole === UserRoles.cohealer && !clientPreviewMode;

  const getClientAccess = () => {
    if (coachCanAccess) {
      return true;
    }
    if (user?.isSecondaryAccount || user?.isSecondaryExisting) {
      return false;
    }
    return currentRole === UserRoles.client && isContributionPurchased;
  };
  const canAccess = getClientAccess();

  const redirectUrl =
    contribution?.type === ContributionType.contributionCommunity || window.location.pathname.includes('community')
      ? window.location.pathname
      : '/about';

  if (
    contribution?.isPurchased &&
    contribution?.isThankyouPageAdded === true &&
    contribution?.isThankyouPageViewd === false &&
    isCoach === false
  ) {
    return <ThankYouPreviewPage />;
  }
  return (
    <>
      {seoDescription ? (
        <SEO description={seoDescription} title={contribution?.title} thumbnails={contribution?.previewContentUrls} />
      ) : null}
      <StyledMainContainer themedColor={themedColor} themedBackgroundColor={themedBackgroundColor}>
        {isMobileSidebarNeeded && (
          <Drawer
            className={classes.drawer}
            style={{ zIndex: 10 }}
            open={!isMobileSidebarOpen}
            onClose={toggleMobilesidebar}
          >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <div style={{ cursor: 'pointer' }} onClick={toggleMobilesidebar}>
                  <CloseIcon
                    style={{
                      marginRight: '30px',
                      color: lightOrDark(colorToUse?.PrimaryColorCode),
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: '100px' }}>
                <ContributionHeader
                  isCoach={isCoach && !clientPreviewMode}
                  canAccess={canAccess}
                  onShareButtonClick={handleShareButtonClick}
                  status={status}
                  columnView
                />
              </div>
            </div>
          </Drawer>
        )}
        <StyledRightSection style={{ maxWidth: mobileView || isCoach ? '100%' : '100%' }}>
          <StickyPortion ref={stickyHeaderRef} isSticky={(stickH || !isCoach || clientPreviewMode) && !mobileView}>
            <Header
              backButtonText={clientPreviewMode ? 'Back' : 'Home'}
              isCustomPrevButton
              noShadow
              isPurchased={isPurchased}
            >
              {!isFinalized && !mobileView && (
                <Can
                  roleName={UserRoles.cohealer}
                  yes={() =>
                    !clientPreviewMode ? (
                      <Link
                        onClick={() => {
                          redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${id}/basic`);
                        }}
                      >
                        <Button
                          disabled={contribution?.status == 'Completed'}
                          backgroundColor={colorToUse?.PrimaryColorCode}
                          textColor={textColor}
                          autoWidth
                        >
                          Edit Service
                        </Button>
                      </Link>
                    ) : null
                  }
                />
              )}
            </Header>
            {mobileView && ((isCoach && !clientPreviewMode) || activeTemplate === TemplateType.TemplateThree) && (
              <HeaderMobile
                changeColor={
                  (activeTemplate === TemplateType.TemplateOne && (!isCoach || clientPreviewMode)) ||
                  (activeTemplate === TemplateType.TemplateTwo && (!isCoach || clientPreviewMode))
                }
              />
            )}
            {(!clientPreviewMode || activeTemplate === TemplateType.TemplateThree) &&
              (currentRole === UserRoles.cohealer ||
                (mobileView && contribution?.isPurchased === true) ||
                activeTemplate === TemplateType.TemplateThree ||
                (mobileView && isPurchased)) && (
                // ||
                // contribution?.type === ContributionType.contributionOneToOne
                // (mobileView && activeTemplate === TemplateType.TemplateOne) ||
                // (mobileView && activeTemplate === TemplateType.TemplateTwo))
                <ContributionHeader
                  isCoach={isCoach && !clientPreviewMode}
                  canAccess={canAccess}
                  onShareButtonClick={handleShareButtonClick}
                  status={status}
                />
              )}
          </StickyPortion>

          <StyledMainSection
            xsView={xsView}
            themedBackgroundColor={themedBackgroundColor}
            isDarkModeEnabled={contribution?.isDarkModeEnabled}
            mobileView={mobileView}
            activeTemplate={activeTemplate}
            isCommunity={window.location.pathname.includes('community')}
            isCoach={isCoach && !clientPreviewMode}
            type={contribution?.type}
            isSticky={
              (stickH || !isCoach) && !mobileView && contribution?.type != ContributionType.contributionOneToOne
            }
            {...{
              devModeSessionsTab,
              isAboutPagePath: pathname?.toLowerCase()?.includes('about'),
              isLoggedIn,
              isSessionsTab: pathname?.toLowerCase()?.includes('/sessions'),
              isNonOneToOne: contribution?.type !== ContributionType.contributionOneToOne,
              isPurchased,
              isCommunicationTab,
            }}
            // clientPreviewMode={clientPreviewMode}
          >
            {clientPreviewMode && (
              <ClientPreviewBanner style={{ color: 'white', margin: '20px 16px' }} colorToUse={colorToUse}>
                <Grid container style={{ width: '100%', margin: '0' }}>
                  <Grid style={{ margin: 'auto' }} item xs={12} sm={12} md={12} lg={12}>
                    <span style={{ color: textColor }}>{`This is a preview what your client will see.`}</span>
                  </Grid>
                  {/* <Grid item xs={6} sm={3} md={2} lg={2}></Grid> */}
                </Grid>
              </ClientPreviewBanner>
            )}
            {isCoach &&
              contribution.isApplicationFormBannerPreviewed === 'false' &&
              showApplicationFormBanner &&
              !contribution.applicationFormViewModel &&
              !clientPreviewMode &&
              contribution?.isAccountNeeded && (
                <ApplicationFormBanner
                  style={{
                    color: 'white',
                    margin: '20px 16px',
                  }}
                  colorToUse={colorToUse}
                  isCommunicationTab={isCommunicationTab}
                  id="app-form-banner"
                >
                  <Grid container style={{ width: '100%', margin: '0', backgroundColor: colorToUse.AccentColorCode }}>
                    <Grid style={{ margin: '0px' }} item xs={6} sm={9} md={10} lg={10}>
                      <span style={{ color: textColorAccent }}>
                        {`Would you like to create an application form for ${contribution.title}?`}
                      </span>
                      <Tooltip
                        title="You can create an application form for potential clients to fill out prior to signing up for this service. This will generate a new application link & you’ll be able to approve or deny applications."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon
                          style={{ marginLeft: '10px', marginBottom: '3px' }}
                          htmlColor={colorToUse.PrimaryColorCode}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2}>
                      <>
                        <button
                          className="btn btn-default float-right"
                          onClick={() => {
                            setShowApplicationFormPopup(true);
                          }}
                          style={{ color: textColorAccent, padding: '0px', marginLeft: '10px' }}
                          type="button"
                        >
                          Yes
                        </button>
                        <button
                          className="btn btn-default float-right"
                          style={{ color: textColorAccent, padding: '0px' }}
                          type="button"
                          onClick={() => setBannerPreviewedStatusInContribution()}
                        >
                          No
                        </button>
                      </>
                    </Grid>
                  </Grid>
                </ApplicationFormBanner>
              )}

            <CreateApplicationFormModal
              showApplicationFormPopup={showApplicationFormPopup}
              setShowApplicationFormPopup={setShowApplicationFormPopup}
              onSuccess={() => {
                setBannerPreviewedStatusInContribution();
                setShowApplicationCreatedPopup(true);
              }}
              onClose={() => {
                setShowApplicationFormPopup(false);
              }}
              isEditMode
              contributionViewMode
              modalType="new"
            />

            {activeTemplate === TemplateType.TemplateOne &&
            (!isCoach || clientPreviewMode) &&
            contribution?.type != ContributionType.contributionOneToOne ? (
              <>
                <Grid
                  style={{
                    color: themedColor,
                    backgroundColor: themedBackgroundColor,
                    display: 'flex',
                    justifyContent: 'space-around',
                    ...(devModeSessionsTab && {
                      width: '100%',
                      // margin: 0,
                      // padding: 0,
                    }),
                  }}
                  container
                >
                  <Grid
                    item
                    md={!calendarTab && (!isPurchased ? 7 : 12)}
                    xs={12}
                    style={{
                      ...(devModeSessionsTab && { margin: 0, padding: 0 }),
                    }}
                  >
                    <Switch>
                      <Route path={`${path}/:code?/thankyoupage`} component={ThankYouPreviewPage} />
                      <Route path={`${path}/:code?/about`} exact component={AboutContribution} />
                      <Route path={`${path}/:code?/clientpreview/about`} exact component={AboutContribution} />
                      <Route path={`${path}/:code?/clientpreview/sessions`} exact component={ContributionSessions} />
                      <Route path={`${path}/:code?/sessions`} exact component={ContributionSessions} />
                      <Route
                        path={`${path}/:code?/sessions/:sessionId?/:contentId?`}
                        exact
                        component={ContributionSessions}
                      />
                      {/* <Route
                        // path={`${path}/:code?/sessions/dev/:sessionId?`}
                        path={`${path}/:code?/sessions/:sessionId?/:contentId?`}
                        exact
                        component={routeProps => {
                          
                          return <ContributionSessions {...{ ...routeProps, stickyHeaderRef }} />;
                        }}
                      /> */}
                      {/* <Route path={`${path}/:code?/sessions/:sessionId?`} exact component={ContributionSessions} /> */}
                      <Route path={`${path}/:code?/calendar`} exact component={ContributionCalendars} />
                      <Route path={`${path}/:code?/clientpreview/calendar`} exact component={ContributionCalendars} />
                      <Route path={`${path}/:code?/community`} component={ContributionCommunity} />
                      <Route path={`${path}/:code?/discussion`} component={ContributionDiscussion} />
                      <Route path={`${path}/:code?/notes`} component={ContributionNotes} />
                      <Route path={`${path}/:code?/team`} component={ContributionTeam} />
                      <Route path={`${path}/:code?/security`} exact component={SecurityContribution} />
                      <Route path={`${path}/:code?/reviews`} exact component={ContributionReviews} />
                      <Route path={`${path}/:code?/resources`} exact component={UserResources} />

                      <Redirect to={`${url + redirectUrl}`} />
                    </Switch>
                  </Grid>

                  {!calendarTab && !mobileView && !isPurchased && (
                    <Grid item md={4} xs={12}>
                      <div style={{ position: 'sticky', top: '140px' }}>
                        <PaymentCard
                          contribution={contribution}
                          type={contribution?.type}
                          isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                          brandingColors={brandingColors}
                          onJoin={setShouldRedirect}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Switch>
                  <Route path={`${path}/:code?/thankyoupage`} component={ThankYouPreviewPage} />
                  <Route path={`${path}/:code?/about`} exact component={AboutContribution} />
                  <Route path={`${path}/:code?/clientpreview/about`} exact component={AboutContribution} />
                  <Route path={`${path}/:code?/clientpreview/sessions`} exact component={ContributionSessions} />
                  <Route
                    path={`${path}/:code?/sessions/:sessionId?/:contentId?`}
                    exact
                    component={ContributionSessions}
                  />
                  {/* <Route
                    // path={`${path}/:code?/sessions/dev/:sessionId?`}
                    path={`${path}/:code?/sessions/:sessionId?/:contentId?`}
                    exact
                    component={routeProps => {
                      return <ContributionSessions {...{ ...routeProps, stickyHeaderRef }} />;
                    }}
                  /> */}
                  {/* <Route path={`${path}/:code?/sessions/:sessionId?`} exact component={ContributionSessions} /> */}
                  <Route path={`${path}/:code?/calendar`} exact component={ContributionCalendars} />
                  <Route path={`${path}/:code?/clientpreview/calendar`} exact component={ContributionCalendars} />
                  <Route path={`${path}/:code?/community`} component={ContributionCommunity} />
                  <Route path={`${path}/:code?/discussion`} component={ContributionDiscussion} />
                  <Route path={`${path}/:code?/notes`} component={ContributionNotes} />
                  <Route path={`${path}/:code?/team`} component={ContributionTeam} />
                  <Route path={`${path}/:code?/security`} exact component={SecurityContribution} />
                  <Route path={`${path}/:code?/reviews`} exact component={ContributionReviews} />
                  <Route path={`${path}/:code?/communication`} exact component={ContributionCommunication} />
                  <Route path={`${path}/:code?/resources`} exact component={UserResources} />
                  <Redirect to={`${url + redirectUrl}`} />
                </Switch>
              </>
            )}
          </StyledMainSection>
        </StyledRightSection>
        {children}
        {modalVisible && (
          <Modal
            isOpen={modalVisible}
            onSubmit={handleCloseModal}
            title={'Access Revoked'}
            hiddenCancel
            onCancel={handleCloseModal}
          >
            <p style={{ color: isDarkThemeEnabled ? 'white' : '' }}>
              Your access has been revoked by coach, please contact coach.
            </p>
          </Modal>
        )}
      </StyledMainContainer>
      {/* {accessExpiredModal && (
        <Modal
          isOpen={accessExpiredModal}
          onSubmit={handleCloseAccessModal}
          title={'Access Expired'}
          hiddenCancel
          onCancel={handleCloseAccessModal}
          minHeight="160px"
        >
          <StyledContactInfo>
            CONTACT
            <StyledEmail href={`mailto:${contribution?.serviceProviderEmail}`}>
              {contribution?.serviceProviderEmail}
            </StyledEmail>
          </StyledContactInfo>
        </Modal>
      )} */}
      {isShowPartnerModal && (
        <Modal
          isOpen={isShowPartnerModal}
          onSubmit={handleClosePartnerMadal}
          title={isAddedSuccessfully ? 'You were added successfully' : 'Adding failed'}
          hiddenCancel
        >
          {isAddedSuccessfully ? (
            <LabelText
              isDarkThemeEnabled={isDarkThemeEnabled}
            >{`You were added to ${partnerContributionName}`}</LabelText>
          ) : (
            <LabelText isDarkThemeEnabled={isDarkThemeEnabled}>Adding you as a partner coach failed</LabelText>
          )}
        </Modal>
      )}
      {getShowLoginPasswordPopup() && isOpenPasswordPopUp && (
        <Modal
          title="Create a Cohere Login Password"
          isOpen={isOpenPasswordPopUp}
          submitTitle="Close"
          hiddenCancel
          disableFooter
          onCancel={() => {
            setIsOpenPasswordPopUp(false);
            setNoPasswordModalVisible(true);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setIsOpenPasswordPopUp(false);
          }}
          style={{ zIndex: '1200' }}
        >
          <p style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
            Finish creating your login account to get access to all the content shared by{' '}
            {contribution?.serviceProviderName} inside {contribution?.title}!
          </p>
          {/* {!user?.UpdatePasswordPopupText && (
            <p style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
              If you don’t create a password now, we’ll automatically send you a temporary password via email in 10
              minutes!
            </p>
          )} */}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Password: Yup.string()
                .strict(true)
                .trim('Please remove spaces after your password')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
                // .required(<Translate id="validation.required" />),
                .required(EN_MESSAGES.validation.required),
            })}
            onSubmit={editHandler}
          >
            {({ values, errors, handleBlur, touched, handleChange }) => (
              <Form className="passowrd-popup-form">
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ width: '50%' }}>
                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      margin="normal"
                      variant="outlined"
                      name="Password"
                      type="password"
                      fullWidth
                      placeholder="Create a password"
                      PostalCode={values.Password}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                      }}
                      error={touched.Password && Boolean(errors.Password)}
                      helperText={errors.Password}
                    />
                  </span>
                  {error?.message && <CommonErrorMessage message={error.message} />}
                </Box>
                <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                  <DialogActions className={classes.actions}>
                    <Button
                      style={{
                        height: '50px',
                        width: '130px',
                        backgroundColor: isDarkThemeEnabled && colorToUse?.PrimaryColorCode,
                      }}
                      type="submit"
                      //   disabled={!isStripeSupportedCuntrySelected(values.CountryId) || loading}
                    >
                      Save
                      {/* {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save'} */}
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {showApplicationCreatedPopup && (
        <Modal
          isOpen={showApplicationCreatedPopup}
          applyTheming
          title="Confirmation"
          className="app-form-created-success"
          onCancel={() => {
            setLoading(true);
            setShowApplicationCreatedPopup(false);
            setLoading(false);
          }}
          onSubmit={handleModalOpen}
          submitTitle="Invite"
        >
          <div>Your application is successfully created</div>
        </Modal>
      )}
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={() => setModalOpened(false)}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={contributionId}
          paymentInfo={contribution?.paymentInfo || {}}
          type={contribution?.type || ''}
          isInvoiced={contribution?.isInvoiced}
          symbol={contribution?.defaultSymbol || '$'}
        />
      )}
    </>
  );
};

ContributionViewMainContainer.propTypes = {
  handleShareButtonClick: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    purchaseStatus: PropTypes.string,
    isPurchased: PropTypes.bool,
    isAccountNeeded: PropTypes.bool,
    paymentInfo: PropTypes.shape([]),
  }),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentRole: PropTypes.string.isRequired,
};

ContributionViewMainContainer.defaultProps = {
  handleShareButtonClick: () => {},
  children: null,
  contribution: null,
  error: null,
};

const mapStateToProps = ({ contributions, account, oneToOneData }) => ({
  contribution: contributions?.activeContribution,
  inviteCode: contributions?.inviteCode,
  error: contributions?.error,
  loading: contributions?.loading,
  currentRole: account?.currentRole,
  startTimeIncrementDuration: oneToOneData?.startTimeIncrementDuration,
  sessionDuration: oneToOneData?.sessionDuration,
});

const actions = {
  getClientContribution: fetchClientContribution,
  getCohealerContribution: fetchCohealerContribution,
  getContributionTimes: fetchContributionTimes,
};

export default connect(mapStateToProps, actions)(ContributionViewMainContainer);
// export default memo(connect(mapStateToProps, actions)(ContributionViewMainContainer), isEqual);
